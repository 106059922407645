import { IPost, IBid } from './Post';
import { IUser } from './User';
import { Transaction } from './Transaction';
import { Message } from './Message';
import { Review } from './Review';

export enum OrderHistoryActionType {
    ADDED = 'added',
    SELLER_FINAL_FEE = 'seller_final_fee',
    BUYER_FINAL_FEE = 'buyer_final_fee',
    PAID = 'paid',
    REFUNDED = 'refunded',
    MESSAGE_SENT = 'message_sent',
    ADMIN_REFUND = 'admin_refund',
    LEFT_FEEDBACK = 'left_feedback',
}

export interface OrderHistoryAction {
    id: string;
    action: OrderHistoryActionType;
    date: Date;
    source: JSX.Element | string;
    description: JSX.Element | string;
}

export interface Order {
    id: string;
    _id: string;
    count: number;
    post_id: string;
    bulk_id?: string;
    bidder_id: string;
    fee_tx: string;
    final_tx: string[];
    amount: number;
    status: string;
    createdAt: Date;
    is_tp: boolean;
    tp_min: number;

    post: IPost;
    client: IUser;
    seller: IUser;
    feeTx: Transaction;
    finalTxs: Transaction[];
    depositTx: Transaction;
    messages: Message[];
    feedback: Review[];
}
