import { Reducer } from 'redux';
import { SettingState } from './types';
import { SettingActionTypes } from './types';
import { IUser } from '../../types/User';
import { ICategory } from '../../types/Category';
import { ISetting, IEffect, Translation, SettingMap } from '../../types/Setting';
import { LockKey } from '../../types/LockKey';

// Type-safe initialState!
const initialState: SettingState = {
    settings: [] as ISetting[],
    effects: [] as IEffect[],
    lockKeys: [] as LockKey[],
    errors: undefined,
    loading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SettingState> = (state = initialState, action) => {
    switch (action.type) {
        case SettingActionTypes.FETCH_EFFECTS ||
            SettingActionTypes.FETCH_LOCKKEYS ||
            SettingActionTypes.ADD_LOCKKEY ||
            SettingActionTypes.REMOVE_LOCKKEY ||
            SettingActionTypes.UPDATE_LOCKKEY ||
            // SettingActionTypes.DB_BACKUP ||
            SettingActionTypes.DB_RESTORE ||
            SettingActionTypes.DB_BACKUP_CHECK ||
            SettingActionTypes.FETCH_TRANSLATION ||
            SettingActionTypes.UPDATE_TRANSLATION ||
            SettingActionTypes.FETCH_SETTINGS: {
            return { ...state, loading: true };
        }
        // case SettingActionTypes.FETCH_SUCCESS: {
        //     console.log(action.type, action.payload)
        //     return {
        //         ...state, loading: false,
        //         category: action.payload.user,
        //     }
        // }
        case SettingActionTypes.FETCHED_TRANSLATION: {
            const lang: 'he' | 'en' = action.payload.lang;
            const translation: Translation = action.payload.translation;
            if (lang === 'en') return { ...state, loading: false, clientTranslationEn: translation };
            else if (lang === 'he') return { ...state, loading: false, clientTranslationHe: translation };
            else return { ...state, loading: false };
        }
        case SettingActionTypes.DB_BACKUP_CHECKED: {
            return { ...state, loading: false, backupChecks: Object.assign({}, action.payload) };
        }
        case SettingActionTypes.FETCHED_LOCKKEYS: {
            return { ...state, loading: false, lockKeys: action.payload };
        }
        case SettingActionTypes.FETCHED_SETTINGS: {
            const settingsMap = action.payload.reduce(
                (obj: SettingMap, item: { key: string; value: string }) => ((obj[item.key] = item.value), obj),
                {},
            );
            return { ...state, loading: false, settings: action.payload, settingsMap };
        }
        case SettingActionTypes.FETCHED_EFFECTS: {
            return { ...state, loading: false, effects: action.payload };
        }

        case SettingActionTypes.FETCHED_SENTENCES: {
            return { ...state, loading: false, sentences: action.payload };
        }

        case SettingActionTypes.FETCHED_PROMOS: {
            return { ...state, loading: false, promos: action.payload };
        }

        case SettingActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as settingReducer };
