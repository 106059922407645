import * as React from 'react';

// import { Button, FormGroup, Label, Input } from 'reactstrap';
import HeaderNav from './homeParts/HeaderNav';
// import UserCard from './homeParts/UserCard';

// import * as Web3 from 'web3';
// import { InputGroup, InputGroupAddon, Input, Alert } from 'reactstrap';

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
// import { IUser } from 'src/types/User';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Page404 from './Page404';
import { Grid, Loader } from 'semantic-ui-react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Admin.css';
import { ApplicationState, ConnectedReduxProps } from '../store';
// import UserView from './homeParts/UserView';
import { IUser } from '../types/User';
import { tokenReceived } from '../store/login/actions';
// import { fetchUser } from "../store/user/actions";
// import * as jwt from 'jsonwebtoken';
// import SettingsView from './homeParts/SettingsView';
// import PostView from './homeParts/PostView';
// import CategoryView from './homeParts/CategoryView';
// import PageTemplateView from './homeParts/PageTemplateView';
// import EmailTemplateView from './homeParts/EmailTemplateView';
// import StoreTypesView from './homeParts/StoreTypesView';
// import FilterView from './homeParts/FilterView';
// import SurveyView from './homeParts/SurveyView';
// import CouponView from './homeParts/CouponView';
// import OrderView from './homeParts/OrderView';
// import Dashboard from './homeParts/Dashboard';
// import StoreView from './homeParts/StoreView';
import { lazy, Suspense } from 'react';
import { fetchUser } from '../store/user/actions';
import { isAllowed } from '../constants';
// import Loader from './Loader';

const UserView = lazy(() => import('./homeParts/UserView'));
const PromoRegView = lazy(() => import('./homeParts/PromoRegView'));
const SettingsView = lazy(() => import('./homeParts/SettingsView'));
const PostView = lazy(() => import('./homeParts/PostView'));
const CategoryView = lazy(() => import('./homeParts/CategoryView'));
const PageTemplateView = lazy(() => import('./homeParts/PageTemplateView'));
const EmailTemplateView = lazy(() => import('./homeParts/EmailTemplateView'));
const StoreTypesView = lazy(() => import('./homeParts/StoreTypesView'));
const FilterView = lazy(() => import('./homeParts/FilterView'));
const SurveyView = lazy(() => import('./homeParts/SurveyView'));
const CouponView = lazy(() => import('./homeParts/CouponView'));
const OrderView = lazy(() => import('./homeParts/OrderView'));
const Dashboard = lazy(() => import('./homeParts/Dashboard'));
const StoreView = lazy(() => import('./homeParts/StoreView'));

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState {
    loggedIn: boolean;
    user: IUser;
    errors?: string;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    fetchUser: typeof fetchUser;
    tokenReceived: typeof tokenReceived;
}

export interface IProps {}

interface IState {
    isAdminLoaded: boolean;
}

type AllProps = IProps & RouteComponentProps<{}> & ConnectedReduxProps & PropsFromState & PropsFromDispatch;

class Admin extends React.Component<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            isAdminLoaded: false,
        };
        const token = localStorage.getItem('token');
        if (token) {
            // var decodedToken = jwt.decode(token, { complete: true });
            // console.log(token, decodedToken);
            this.props.tokenReceived(token);
            if (!props.user.id) this.props.fetchUser();
        } else this.props.history.push('/');
    }

    componentDidUpdate(prevProps: AllProps, prevState: IState) {
        // if (this.props.admin && this.props.admin.id != "" && !this.state.isAdminLoaded) {
        //     console.log("admin loaded")
        //     this.setState({ isAdminLoaded: true })
        // }
    }

    public render() {
        if (!this.props.user.id) return <Loader />;
        return (
            <div>
                <HeaderNav {...this.props} />
                <Grid.Row>
                    <Grid.Column>
                        <section className="route-section">
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    {isAllowed(this.props.user, 'dashboard') && (
                                        <Redirect exact from="/admin/" to="/admin/dashboard/users/total" />
                                    )}
                                    {isAllowed(this.props.user, 'dashboard') && (
                                        <Route path="/admin/dashboard/:mode/:submode" component={Dashboard} />
                                    )}
                                    {isAllowed(this.props.user, 'users') && (
                                        <Route path="/admin/users/" component={UserView} />
                                    )}
                                    {isAllowed(this.props.user, 'users') && (
                                        <Route path="/admin/promoreg/" component={PromoRegView} />
                                    )}
                                    {isAllowed(this.props.user, 'categories') && (
                                        <Route path="/admin/categories/" component={CategoryView} />
                                    )}
                                    {isAllowed(this.props.user, 'storeTypes') && (
                                        <Route path="/admin/storetypes/" component={StoreTypesView} />
                                    )}
                                    {isAllowed(this.props.user, 'posts') && (
                                        <Route path="/admin/posts/" component={PostView} />
                                    )}
                                    {isAllowed(this.props.user, 'stores') && (
                                        <Route path="/admin/stores/" component={StoreView} />
                                    )}
                                    {isAllowed(this.props.user, 'settings') && (
                                        <Route path="/admin/settings/" component={SettingsView} />
                                    )}
                                    {isAllowed(this.props.user, 'pageTemplates') && (
                                        <Route path="/admin/pagetemplates/" component={PageTemplateView} />
                                    )}
                                    {isAllowed(this.props.user, 'emailTemplates') && (
                                        <Route path="/admin/emailtemplates/" component={EmailTemplateView} />
                                    )}
                                    {isAllowed(this.props.user, 'filters') && (
                                        <Route path="/admin/filters/" component={FilterView} />
                                    )}
                                    {isAllowed(this.props.user, 'manageSurvey') && (
                                        <Route path="/admin/surveys/" component={SurveyView} />
                                    )}
                                    {isAllowed(this.props.user, 'coupons') && (
                                        <Route path="/admin/coupons/" component={CouponView} />
                                    )}
                                    {isAllowed(this.props.user, 'orders') && (
                                        <Route path="/admin/orders/" component={OrderView} />
                                    )}

                                    <Route component={Page404} />
                                </Switch>
                            </Suspense>
                        </section>
                        {/* </CSSTransition>
                        </TransitionGroup> */}
                    </Grid.Column>
                </Grid.Row>
            </div>
        );
    }
}

const mapStateToProps = ({ login, user }: ApplicationState) => ({
    loggedIn: login.loading,
    errors: login.errors,
    user: user.user,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUser: () => dispatch(fetchUser()),
    tokenReceived: (token: string) => dispatch(tokenReceived(token)),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Admin);
