import { IFilter } from "../../types/Filter";

export enum FilterActionTypes {
    FETCHALL = '@@filter/FETCHALL',
    FETCHEDALL = '@@admin/FETCHEDALL',

    UPDATE = '@@filter/UPDATE',
    REMOVE = '@@admin/REMOVE',
    ADD = '@@admin/ADD',

    SUCCESS = '@@filter/SUCCESS',
    ERROR = '@@filter/ERROR',
    // FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS"
}

export interface FilterState {
    readonly loading: boolean;
    readonly filters: IFilter[]
    readonly errors?: string
}