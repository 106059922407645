import { combineReducers, Action, AnyAction, Dispatch } from 'redux';
import { LayoutState, layoutReducer } from './layout';
import { all, fork } from 'redux-saga/effects';
import { userSaga } from './user/sagas';
// import { UserState } from './user/types';
import { LoginState } from './login/types';
// import { userReducer } from './user/reducer';
import { loginReducer } from './login/reducer';
import { loginSaga } from './login/sagas';
import { UserState } from './user/types';
import { userReducer } from './user/reducer';
import { CategoryState } from './category/types';
import { categoryReducer } from './category/reducer';
import { categorySaga } from './category/sagas';
import { PostState } from './post/types';
import { postReducer } from './post/reducer';
import { postSaga } from './post/sagas';
import { PageState } from './page/types';
import { EmailState } from './email/types';
import { pageTemplateReducer } from './page/reducer';
import { emailTemplateReducer } from './email/reducer';
import { pageSaga } from './page/sagas';
import { emailSaga } from './email/sagas';
import { SettingState } from './setting/types';
import { settingReducer } from './setting/reducer';
import { settingSaga } from './setting/sagas';
import { storeTypeSaga } from './stores/sagas';
import { StoreTypeState } from './stores/types';
import { storeTypeReducer } from './stores/reducer';
import { filterReducer } from './filter/reducer';
import { FilterState } from './filter/types';
import { filterSaga } from './filter/sagas';
import { SurveyState } from './survey/types';
import { surveyReducer } from './survey/reducer';
import { surveySaga } from './survey/sagas';
import { CouponState } from './coupon/types';
import { couponSaga } from './coupon/sagas';
import { couponReducer } from './coupon/reducer';

export interface ApplicationState {
    layout: LayoutState;
    user: UserState;
    login: LoginState;
    category: CategoryState;
    post: PostState;
    page: PageState;
    email: EmailState;
    setting: SettingState;
    storeType: StoreTypeState;
    filter: FilterState;
    survey: SurveyState;
    coupon: CouponState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const rootReducer = combineReducers<ApplicationState>({
    layout: layoutReducer,
    user: userReducer,
    login: loginReducer,
    category: categoryReducer,
    post: postReducer,
    page: pageTemplateReducer,
    email: emailTemplateReducer,
    setting: settingReducer,
    storeType: storeTypeReducer,
    filter: filterReducer,
    survey: surveyReducer,
    coupon: couponReducer,
});

// We `fork()` these tasks so they execute in the background.
export function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(userSaga),
        fork(categorySaga),
        fork(postSaga),
        fork(pageSaga),
        fork(emailSaga),
        fork(settingSaga),
        fork(storeTypeSaga),
        fork(filterSaga),
        fork(surveySaga),
        fork(couponSaga),
    ]);
}
