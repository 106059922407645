import { IUser, OnlineUser } from '../../types/User';
import { Order } from '../../types/Order';
import { Stats, FinanceStats } from '../../types/Stats';
import { LoginRecord, LoginTime } from '../../types/LoginRecord';
import { Expense } from '../../types/Expense';
import { ICurrency } from '../../types/Currency';

export enum UserActionTypes {
    FETCH_USER = '@@user/FETCH_USER',
    UPDATE_USER = '@@user/UPDATE_USER',
    USER_UPDATED = '@@user/USER_UPDATED',
    UPLOAD_AVATAR = '@@user/UPLOAD_AVATAR',
    FETCH_USERS = '@@user/FETCH_USERS',
    FETCHED_USERS = '@@admin/FETCHED_USERS',
    REMOVE_USER = '@@admin/REMOVE_USER',
    ADD_USER = '@@admin/ADD_USER',
    FETCH_SUCCESS = '@@user/FETCH_SUCCESS',
    FETCH_ERROR = '@@user/FETCH_ERROR',
    FETCH_ALLSUCCESS = '@@user/FETCH_ALLSUCCESS',
    FETCH_RATES = '@@user/FETCH_RATES',
    RATES_FETCHED = '@@user/RATES_FETCHED',

    FETCH_ORDERS = '@@user/FETCH_ORDERS',
    FETCHED_ORDERS = '@@user/FETCHED_ORDERS',
    REFUND_ORDER = '@@user/REFUND_ORDER',

    TOTAL_STATS = '@@stats/TOTAL',
    TOTAL_STATS_LOADED = '@@stats/TOTAL_LOADED',

    FETCH_HISTORY = '@@user/FETCH_HISTORY',
    FETCHED_HISTORY = '@@user/FETCHED_HISTORY',

    SEND_MESSAGE = '@@user/SEND_MESSAGE',
    OK_SEND_MESSAGE = '@@user/OK_SEND_MESSAGE',

    FETCH_EXPENSES = '@@exp/FETCH_EXPENSES',
    FETCHED_EXPENSES = '@@exp/FETCHED_EXPENSES',
    ADD_EXPENSE = '@@exp/ADD_EXPENSES',
    UPDATE_EXPENSE = '@@exp/UPDATE_EXPENSE',
    REMOVE_EXPENSE = '@@exp/REMOVE_EXPENSE',

    FETCH_FINANCE_STATS = '@@stats/FETCH_FINANCE_STATS',
    FETCHED_FINANCE_STATS = '@@stats/FETCHED_FINANCE_STATS',

    CANCEL_TRANSACTION = '@@tx/CANCEL',

    INITIALIZE_SOCKET = '@@scoket/INIT',
    SOCKET_LISTENUSERS = '@@scoket/LISTENUSERS',
    SOCKET_LISTENUSERS_RECEIVED = '@@scoket/SOCKET_LISTENUSERS_RECEIVED',
}

export interface UserState {
    readonly loading: boolean;
    readonly users: IUser[];
    readonly orders: Order[];
    readonly expenses: Expense[];
    readonly financeStats?: FinanceStats;
    readonly user: IUser;
    readonly errors?: string;
    readonly totalStats?: Stats;
    readonly rates: ICurrency;

    readonly onlineHistory: LoginRecord[];
    readonly onlineTime: LoginTime[];
    readonly onlineUsers: OnlineUser[];
}
