import * as React from 'react';

function Page404() {

    return (
        <div className="404">
            ...
        </div>
    );
}

export default Page404;
