import { IPageTemplate } from "../../types/PageTemplate";

export enum PageActionTypes {
    FETCH_PAGETEMPLATE = '@@pageTemplate/FETCH_PAGETEMPLATE',
    UPDATE_PAGETEMPLATE = '@@pageTemplate/UPDATE_PAGETEMPLATE',
    PAGETEMPLATE_UPDATED = '@@pageTemplate/PAGETEMPLATE_UPDATED',
    FETCH_PAGETEMPLATES = '@@pageTemplate/FETCH_PAGETEMPLATES',
    FETCHED_PAGETEMPLATES = '@@admin/FETCHED_PAGETEMPLATES',
    REMOVE_PAGETEMPLATE = '@@admin/REMOVE_PAGETEMPLATE',
    ADD_PAGETEMPLATE = '@@admin/ADD_PAGETEMPLATE',
    FETCH_SUCCESS = '@@pageTemplate/FETCH_SUCCESS',
    FETCH_ERROR = '@@pageTemplate/FETCH_ERROR',
    FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS"
}

export interface PageState {
    readonly loading: boolean;
    readonly pageTemplates: IPageTemplate[]
    readonly pageTemplate: IPageTemplate
    readonly errors?: string
}