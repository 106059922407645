export enum ExpenseType {
    PROMOTION_MARKETING = "promotion_marketing",
    DIGITAL_MARKETING = "digital_marketing",
    DEVELOP = "develop",
    HOSTING = "hosting",
    ADMIN = "admin",
    HR = "hr",
    COMMISSION = "commission"
}

export enum ExpensePeriodType {
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
    QUARTERLY = "quarterly",
    YEARLY = "yearly"
}

export interface Expense {
    id: string;
    type: ExpenseType;
    amount: number;
    title: string;
    description: string;
    owner: string;
    expensePeriod: ExpensePeriodType;
    dailyAmount: number;
    periodFrom: Date;
    periodTo: Date;
    createdAt: Date;
    updatedAt: Date;
}
