import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Menu, Button, Select, DropdownItemProps } from 'semantic-ui-react';
import { IUser, OnlineUser } from '../../types/User';
import { logout } from '../../store/login/actions';
import { fetchSuccess, fetchRates, updateUser } from '../../store/user/actions';
import { ConnectedReduxProps, ApplicationState } from '../../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isAllowed, getCurrencySymbol } from '../../constants';
import { ICurrency } from '../../types/Currency';

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState {
    user: IUser;
    loggedIn: boolean;
    loginToken: string;
    errors?: string;
    isRegistered: boolean;
    rates: ICurrency;
    onlineUsers: OnlineUser[];
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    logout: typeof logout;
    updateUser: typeof updateUser;
    fetchSuccess: typeof fetchSuccess;
    fetchRates: typeof fetchRates;
    // registerNew: typeof registerNew
    // authCompleted: typeof authCompleted
}

export interface IProps {}

export interface IState {
    isOpen: boolean;
}

type AllProps = IProps & RouteComponentProps<{}> & ConnectedReduxProps & PropsFromState & PropsFromDispatch;

class HeaderNav extends React.Component<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.toggle = this.toggle.bind(this);
        props.fetchRates();
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    public componentDidMount() {
        // window.addEventListener('scroll', ()=>{
        //     this.navbarCollapse();
        // });
        // window.addEventListener('load', ()=>{
        //     this.onLoad();
        // });
    }

    public async logout() {
        this.props.fetchSuccess({} as IUser);
        this.props.logout();

        this.props.history.push('/');
    }

    nextPath(path: string) {
        this.props.history.push('/admin' + path);
    }

    isCurrent(path: string) {
        return this.props.location.pathname == '/admin' + path;
    }

    getRates = (rates: ICurrency) =>
        Object.keys(rates).map((val, i) => {
            return {
                key: val,
                text: val,
                value: val,
            } as DropdownItemProps;
        });

    public render() {
        // console.log(this.props.user.role !== 'SUPERADMIN', isAllowed(this.props.user, 'dashboard'));
        return (
            <Menu style={{ height: '4vh', overflowX: 'auto', margin: 0 }}>
                <Menu.Item>
                    <img width="100%" src={`${process.env.PUBLIC_URL}/images/bee.png`} />
                </Menu.Item>
                {isAllowed(this.props.user, 'dashboard') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/')}
                        name="dashboard"
                        onClick={() => this.nextPath('/')}
                    >
                        Dashboard{' '}
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'settings') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/settings')}
                        name="settings"
                        onClick={() => this.nextPath('/settings')}
                    >
                        Settings
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'users') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/users')}
                        name="users"
                        onClick={() => this.nextPath('/users')}
                    >
                        Users
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'users') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/promoreg')}
                        name="promoreg"
                        onClick={() => this.nextPath('/promoreg')}
                    >
                        Promo Codes
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'categories') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/categories')}
                        name="categories"
                        onClick={() => this.nextPath('/categories')}
                    >
                        Categories
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'filters') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/filters')}
                        name="categories"
                        onClick={() => this.nextPath('/filters')}
                    >
                        Filters and statuses
                    </Menu.Item>
                )}

                {isAllowed(this.props.user, 'pageTemplates') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/pagetemplates')}
                        name="pagetemplates"
                        onClick={() => this.nextPath('/pagetemplates')}
                    >
                        Page Templates
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'emailTemplates') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/emailtemplates')}
                        name="emailtemplates"
                        onClick={() => this.nextPath('/emailtemplates')}
                    >
                        Email Templates
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'storeTypes') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/storetypes')}
                        name="storetypes"
                        onClick={() => this.nextPath('/storetypes')}
                    >
                        Store Types
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'manageSurvey') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/surveys')}
                        name="surveys"
                        onClick={() => this.nextPath('/surveys')}
                    >
                        Manage Survey
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'coupons') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/coupons')}
                        name="coupons"
                        onClick={() => this.nextPath('/coupons')}
                    >
                        Coupons
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'posts') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/posts')}
                        name="posts"
                        onClick={() => this.nextPath('/posts')}
                    >
                        Posts
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'stores') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/stores')}
                        name="stores"
                        onClick={() => this.nextPath('/stores')}
                    >
                        Stores
                    </Menu.Item>
                )}
                {isAllowed(this.props.user, 'orders') && (
                    <Menu.Item
                        color="blue"
                        active={this.isCurrent('/orders')}
                        name="orders"
                        onClick={() => this.nextPath('/orders')}
                    >
                        Orders
                    </Menu.Item>
                )}
                <Menu.Menu position="right">
                    <Menu.Item
                        color="blue"
                        name={'Online'}
                        onClick={() =>
                            this.nextPath('/dashboard/users/online')
                        }
                    >
                        Currently Online {this.props.onlineUsers.length}
                    </Menu.Item>
                    <Menu.Item>
                        Select Currency{' '}
                        <select
                            name="cur_select"
                            id=""
                            // className="language-selector"
                            style={{
                                marginTop: 0,
                                marginRight: 0,
                                float: 'right',
                            }}
                            value={this.props.user.currency || 'USD'}
                            onChange={val => {
                                const user = this.props.user;
                                user.currency = (val as any).target.value;
                                // user.email = user.email ? user.email : '';
                                this.props.updateUser(user);
                                // window.location.reload();
                                // this.props.history.push('/');
                                this.props.fetchSuccess(user);
                            }}
                        >
                            {Object.keys(this.props.rates).map((rate, i) => (
                                <option key={rate} value={rate}>
                                    {rate} {getCurrencySymbol(rate)}
                                </option>
                            ))}
                        </select>
                        {/* <Select
                            options={this.getRates(this.props.rates)}
                            name={'rate'}
                            value={this.props.user.currency || 'USD'}
                            onChange={(e: any, { value }) => {
                                const user = this.props.user;
                                user.currency = value as string;
                                this.props.updateUser(user);
                                window.location.reload();
                                // this.props.history.push('/');
                                // this.props.fetchSuccessUser(user);
                            }}
                        /> */}
                    </Menu.Item>
                    <Menu.Item color="blue" name={this.props.user.role} onClick={() => {}}>
                        {this.props.user.username} - {this.props.user.role}
                    </Menu.Item>
                    <Menu.Item>
                        <Button primary onClick={async () => await this.logout()}>
                            Log out
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}

const mapStateToProps = ({ login, user }: ApplicationState) => ({
    user: user.user,
    rates: user.rates,
    loggedIn: login.loading,
    errors: login.errors,
    loginToken: login.token,
    isRegistered: login.registerCompleted,
    onlineUsers: user.onlineUsers,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
    fetchSuccess: (user: IUser) => dispatch(fetchSuccess(user)),
    updateUser: (user: IUser) => dispatch(updateUser(user)),
    fetchRates: () => dispatch(fetchRates()),
    // tokenLogin: (loginReq: LoginRequest) => dispatch(tokenLogin(loginReq)),
    // registerNew: (newUser: IUser) => dispatch(registerNew(newUser)),
    // authCompleted: (token: string) => dispatch(authCompleted(token))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderNav);
