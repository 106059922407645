/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { all, call, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { UserActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updateUser,
    fetchUsers,
    uploadAvatar,
    removeUser,
    addUser,
    onFetchedUsers,
    fetchOrders,
    fetchedOrders,
    refundOrder,
    loadedTotalStats,
    fetchHistory,
    fetchedHistory,
    sendMessage,
    messageSent,
    fetchedExpenses,
    updateExpense,
    fetchExpenses,
    removeExpense,
    addExpense,
    fetchFinanceStats,
    fetchedFinanceStats,
    cancelTransaction,
    ratesFetched,
    listenUsersSocket,
    listenUsersSocketReceived,
} from './actions';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
import { IUser, OnlineUser } from '../../types/User';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { Order } from '../../types/Order';
import { Stats, FinanceStats } from '../../types/Stats';
import { LoginRecord, LoginTime } from '../../types/LoginRecord';
import { Expense } from '../../types/Expense';
import moment from 'moment';
import { fetchTransactions } from '../post/actions';
import { getFeedbacks } from '../../constants';
import { ICurrency } from '../../types/Currency';
import { createOnlineUsersChannel } from './channel';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getUser = (state: ApplicationState) => state.user.user;
export const getRates = (state: ApplicationState) => state.user.rates;

function* handleListenUsers(): any {
    const channel = yield call(createOnlineUsersChannel);
    console.log('INIT_SOCKET_listenUsers');
    if (!channel) {
        console.log('EXIST_listenUsers');
        return;
    }
    while (true) {
        const onlineUsers: OnlineUser[] = yield take(channel);
        // let postRes: IPost = JSON.parse(updatedPostData);
        // console.log('SOCKET_listenUsers', onlineUsers);
        yield put(listenUsersSocketReceived(onlineUsers));
    }
}

function* handleLoadStats() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        // console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let statsRes: Stats = yield call(callApi, 'get', API_ENDPOINT, '/stats/', token);
        console.log('fetched stats', statsRes);

        yield put(loadedTotalStats(statsRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchFinanceStats(action: ReturnType<typeof fetchFinanceStats>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const dateFrom = moment(action.payload.from).format('YYYY-MM-DD');
        const dateTo = moment(action.payload.to).format('YYYY-MM-DD');

        let statsRes: FinanceStats = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/stats/finance/${dateFrom}/${dateTo}`,
            token,
        );
        console.log('fetched finance stats', statsRes);

        yield put(fetchedFinanceStats(statsRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUser() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let userRes: IUser = yield call(callApi, 'get', API_ENDPOINT, '/user/me', token);
        console.log('fetched user', userRes);

        yield put(fetchSuccess(userRes));
        yield put(listenUsersSocket());

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchRates() {
    // console.log('fetch rates');
    try {
        const rates = yield select(getRates);
        if (Object.keys(rates).length > 0) {
            yield put(ratesFetched(rates));
            return;
        }

        const res: ApiError & ICurrency = yield call(callApi, 'get', API_ENDPOINT, `/user/rates`, '');
        console.log('fetch rates', res);
        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(ratesFetched(res));
        }
    } catch (err) {
        console.log('err', err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUsers() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let userRes: IUser[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/user/', token);

        // adding reviews
        userRes.forEach(usr => {
            const { fTotal, fCount, fAvg } = getFeedbacks(usr.feedbacks);
            usr.fTotal = fTotal;
            usr.fCount = fCount;
            usr.fAvg = fAvg;
        });
        console.log('fetched users', userRes);

        if (userRes.error) {
            console.log(userRes);
            yield put(fetchError('Error fetching users'));
        } else {
            yield put(onFetchedUsers(userRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateUser(action: ReturnType<typeof updateUser>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/user/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating user'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('User data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchUsers());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveUser(action: ReturnType<typeof removeUser>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/user/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing user'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('User removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchUsers());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddUser(action: ReturnType<typeof addUser>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/`, token, action.payload);

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchUsers());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUploadAvatar(action: ReturnType<typeof uploadAvatar>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);

        // const formData = new FormData()
        //     formData.append(
        //         'newAvatar',
        //         this.state.selectedFile!,
        //         this.state.selectedFile!.name
        //     );
        const data = new FormData();
        data.append('file', action.payload);
        // data.append('filename', this.fileName.value);

        const res: ApiResponse & ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/user/uploadavatar`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating avatar'));
            toast('Error updating avatar', { type: toast.TYPE.ERROR });
        } else {
            toast('Avatar updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchUsers());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchOrder(action: ReturnType<typeof fetchOrders>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: Order[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, `/user/order/view/all`, token);
        console.log('FETCH ORDER', res);
        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(fetchedOrders(res));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchHistory(action: ReturnType<typeof fetchHistory>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: { logins: LoginRecord[]; time: LoginTime[] } & ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/history`,
            token,
        );
        console.log('FETCH logins', res);
        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(fetchedHistory(res.logins, res.time));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRefundOrder(action: ReturnType<typeof refundOrder>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/order/refund/${action.payload.bid._id}${
                action.payload.partialAmount ? '/' + action.payload.partialAmount : ''
            }`,
            token,
        );

        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(`Order was refunded successfully`, { type: 'success' });

            yield put(fetchOrders());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSendMessage(action: ReturnType<typeof sendMessage>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const messageObject = {
            receiverId: action.payload.receiver.id,
            type: 'message',
            emailMessage: action.payload.emailMessage,
            message: action.payload.message,
        };
        console.log(messageObject);
        const res: ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/messages/send/`, token, messageObject);

        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(`Message has been sent to user ${action.payload.receiver.username}`, { type: 'success' });

            yield put(messageSent());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchExpenses() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let expRes: Expense[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/stats/expenses', token);
        console.log('fetched expenses', expRes);

        if (expRes.error) {
            console.log(expRes);
            yield put(fetchError('Error fetching expenses'));
        } else {
            yield put(fetchedExpenses(expRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateExpense(action: ReturnType<typeof updateExpense>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/stats/expenses/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating expense'));
            toast('Error updating expense', { type: toast.TYPE.ERROR });
        } else {
            toast('Expense record updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchExpenses());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveExpense(action: ReturnType<typeof removeExpense>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/stats/expenses/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing expense'));
            toast('Error removing expense', { type: toast.TYPE.ERROR });
        } else {
            toast('Expense removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchExpenses());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddExpense(action: ReturnType<typeof addExpense>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/stats/expenses/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchExpenses());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleCancelTransaction(action: ReturnType<typeof cancelTransaction>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/user/canceltx/${action.payload.txId}`,
            token,
            action.payload.partialAmount ? { partialAmount: action.payload.partialAmount } : undefined,
        );

        // console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error refunding transaction'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('Transaction refunded', { type: toast.TYPE.SUCCESS });
            yield put(fetchTransactions());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
    yield takeEvery(UserActionTypes.FETCH_USER, handleFetchUser);
}
function* watchFetchHistoryRequest() {
    yield takeEvery(UserActionTypes.FETCH_HISTORY, handleFetchHistory);
}
function* watchUpdateUser() {
    yield takeEvery(UserActionTypes.UPDATE_USER, handleUpdateUser);
}
function* watchUploadAvatar() {
    yield takeEvery(UserActionTypes.UPLOAD_AVATAR, handleUploadAvatar);
}
function* watchFetchUsers() {
    yield takeEvery(UserActionTypes.FETCH_USERS, handleFetchUsers);
}
function* watchAddUser() {
    yield takeEvery(UserActionTypes.ADD_USER, handleAddUser);
}
function* watchRemoveUser() {
    yield takeEvery(UserActionTypes.REMOVE_USER, handleRemoveUser);
}
function* watchFetchOrders() {
    yield takeEvery(UserActionTypes.FETCH_ORDERS, handleFetchOrder);
}
function* watchRefundOrder() {
    yield takeEvery(UserActionTypes.REFUND_ORDER, handleRefundOrder);
}
function* watchLoadTotalStats() {
    yield takeEvery(UserActionTypes.TOTAL_STATS, handleLoadStats);
}
function* watchSendMessage() {
    yield takeEvery(UserActionTypes.SEND_MESSAGE, handleSendMessage);
}
function* watchFetchExpenses() {
    yield takeEvery(UserActionTypes.FETCH_EXPENSES, handleFetchExpenses);
}
function* watchAddExpense() {
    yield takeEvery(UserActionTypes.ADD_EXPENSE, handleAddExpense);
}
function* watchRemoveExpense() {
    yield takeEvery(UserActionTypes.REMOVE_EXPENSE, handleRemoveExpense);
}
function* watchUpdateExpense() {
    yield takeEvery(UserActionTypes.UPDATE_EXPENSE, handleUpdateExpense);
}
function* watchFetchFinanceStats() {
    yield takeEvery(UserActionTypes.FETCH_FINANCE_STATS, handleFetchFinanceStats);
}
function* watchCancelTransaction() {
    yield takeEvery(UserActionTypes.CANCEL_TRANSACTION, handleCancelTransaction);
}
function* watchFetchRates() {
    yield takeEvery(UserActionTypes.FETCH_RATES, handleFetchRates);
}
function* watchListenOnline() {
    yield takeEvery(UserActionTypes.SOCKET_LISTENUSERS, handleListenUsers);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* userSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchUpdateUser),
        fork(watchUploadAvatar),
        fork(watchFetchUsers),
        fork(watchAddUser),
        fork(watchRemoveUser),
        fork(watchFetchOrders),
        fork(watchRefundOrder),
        fork(watchLoadTotalStats),
        fork(watchFetchHistoryRequest),
        fork(watchSendMessage),
        fork(watchFetchExpenses),
        fork(watchAddExpense),
        fork(watchRemoveExpense),
        fork(watchUpdateExpense),
        fork(watchFetchFinanceStats),
        fork(watchCancelTransaction),
        fork(watchFetchRates),
        fork(watchListenOnline),
    ]);
}
