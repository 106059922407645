import { action } from 'typesafe-actions';
import { UserActionTypes } from './types';
import { IUser, OnlineUser } from '../../types/User';
import { IBid } from '../../types/Post';
import { Order } from '../../types/Order';
import { Stats, FinanceStats } from '../../types/Stats';
import { LoginRecord, LoginTime } from '../../types/LoginRecord';
import { Expense } from '../../types/Expense';
import { ICurrency } from '../../types/Currency';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const fetchSuccess = (user: IUser) =>
    action(UserActionTypes.FETCH_SUCCESS, {
        user,
    });
export const fetchUser = () => action(UserActionTypes.FETCH_USER);
export const userUpdated = (user: IUser) => action(UserActionTypes.USER_UPDATED, user);

export const onFetchedUsers = (users: IUser[]) => action(UserActionTypes.FETCHED_USERS, users);
export const fetchUsers = () => action(UserActionTypes.FETCH_USERS);
export const updateUser = (user: IUser) => action(UserActionTypes.UPDATE_USER, user);
export const uploadAvatar = (image: File, user: IUser) => action(UserActionTypes.UPLOAD_AVATAR, image);
export const addUser = (user: IUser) => action(UserActionTypes.ADD_USER, user);
export const removeUser = (userId: string) => action(UserActionTypes.REMOVE_USER, userId);

export const fetchOrders = () => action(UserActionTypes.FETCH_ORDERS);
export const fetchedOrders = (orders: Order[]) => action(UserActionTypes.FETCHED_ORDERS, orders);

export const fetchHistory = () => action(UserActionTypes.FETCH_HISTORY);
export const fetchedHistory = (onlineHistory: LoginRecord[], onlineTime: LoginTime[]) => 
action(UserActionTypes.FETCHED_HISTORY, {onlineHistory, onlineTime});

export const refundOrder = (bid: IBid, partialAmount?: number) =>
    action(UserActionTypes.REFUND_ORDER, { bid, partialAmount });

export const fetchAllSuccess = () => action(UserActionTypes.FETCH_ALLSUCCESS);
export const fetchError = (message: string) => action(UserActionTypes.FETCH_ERROR, message);

export const loadTotalStats = () => action(UserActionTypes.TOTAL_STATS);
export const loadedTotalStats = (stats: Stats) => action(UserActionTypes.TOTAL_STATS_LOADED, stats);

export const sendMessage = (receiver: IUser, message: string, emailMessage: boolean) =>
    action(UserActionTypes.SEND_MESSAGE, { receiver, message, emailMessage });
export const messageSent = () => action(UserActionTypes.OK_SEND_MESSAGE);

export const fetchExpenses = () => action(UserActionTypes.FETCH_EXPENSES);
export const fetchedExpenses = (expenses: Expense[]) => action(UserActionTypes.FETCHED_EXPENSES, expenses);

export const updateExpense = (expense: Expense) => action(UserActionTypes.UPDATE_EXPENSE, expense);
export const addExpense = (expense: Expense) => action(UserActionTypes.ADD_EXPENSE, expense);
export const removeExpense = (expenseId: string) => action(UserActionTypes.REMOVE_EXPENSE, expenseId);

export const fetchRates = () => action(UserActionTypes.FETCH_RATES);
export const ratesFetched = (rates: ICurrency) => {
    // console.log('R', rates);
    return action(UserActionTypes.RATES_FETCHED, rates);
};

export const cancelTransaction = (txId: string, partialAmount?: number) =>
    action(UserActionTypes.CANCEL_TRANSACTION, { txId, partialAmount });

export const fetchFinanceStats = (from: Date, to: Date) => action(UserActionTypes.FETCH_FINANCE_STATS, { from, to });
export const fetchedFinanceStats = (financeStats: FinanceStats) =>
    action(UserActionTypes.FETCHED_FINANCE_STATS, financeStats);

export const initializeSocket = () => action(UserActionTypes.INITIALIZE_SOCKET);
export const listenUsersSocket = () => action(UserActionTypes.SOCKET_LISTENUSERS);
export const listenUsersSocketReceived = (onlineUsers: OnlineUser[]) => action(UserActionTypes.SOCKET_LISTENUSERS_RECEIVED, onlineUsers);
