import { CountryRegionData } from 'react-country-region-selector';
import { Expense, ExpensePeriodType } from '../types/Expense';
import { createStaticRanges } from 'react-date-range';
import moment from 'moment';
import { Order, OrderHistoryActionType, OrderHistoryAction } from '../types/Order';
import { ICategory } from '../types/Category';
import { IPost, PostHistoryAction, PostHistoryActionType } from '../types/Post';
import { IStore, StoreHistoryAction, StoreHistoryActionType } from '../types/Store';
import { StoreTypeActionTypes } from '../store/stores/types';
import { Review } from '../types/Review';
import { IUser } from '../types/User';
import { StringMap } from 'quill';
import { ICurrency } from '../types/Currency';
import { Fee, ExternalFeeEntry, ISetting, SettingMap } from '../types/Setting';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';

// const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const fileExtension = '.xlsx';

// export const exportToCSV = (csvData: never[], fileName: string) => {
//     const ws = XLSX.utils.json_to_sheet(csvData);
//     const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
//     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     const data = new Blob([excelBuffer], { type: fileType });
//     FileSaver.saveAs(data, fileName + fileExtension);
// };

export const SocketEvents = {
    onlineUsers: 'listenonline',
    // listenPost: (postId: string) => 'listenpost_' + postId,
    // listenMessages: (userId: string) => 'listenmessages_' + userId,
};

export const QUILL_MODULES: StringMap = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        [{ direction: 'rtl' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
        ['link', 'image', 'video'],
        [{ align: [] }],
        ['clean'],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

export const QUILL_FORMATS: string[] = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
    'align',
];

export const ROLES = ['PERSONAL', 'BUSINESS', 'PARTNER', 'ADMIN', 'SUPERADMIN', 'PB USER'];
export const ADDITIONAL_FILEDS_TYPES = ['TEXT', 'NUMBER'];
export const SALE_LOCATIONS = ['Local Only', 'Worldwide', 'By Countries'];
export const DELIVERY_OPTIONS = ['Free', 'No Delivery', 'With Fee'];
export const AD_TYPES = ['beediT', 'Beed Auction'];
export const DELIVERY_LOCATIONS = ['Local Only', 'Worldwide', 'By Countries'];
export const AD_FRAME_COLORS = ['None', 'Blue', 'Red', 'Yellow', 'Purple', 'Green', 'Black'];
export const AD_EFFECTS = ['None', 'Stars', 'Hearts', 'Bee', 'Bling Bling', 'HOT'];
export const PRODUCT_STATUS = ['New', 'Old', 'Broken'];
export const BACKUP_TYPES = [
    'categories',
    'effects',
    'emailTemplates',
    'storeTypes',
    'filters',
    'pageTemplates',
    'posts',
    'bids',
    'stores',
    'surveys',
    'users',
    'transactions',
    'expenses',
    'lockKeys',
    'loginRecords',
    'messages',
    'settings',
    'coupons',
];
export const MISSING_IMAGE = `${process.env.PUBLIC_URL}/images/bottom_img.png`;
// export const NO_IMAGE = `${process.env.PUBLIC_URL}/images/no-image.jpg`;
export const NO_IMAGE = `${process.env.PUBLIC_URL}/images/bee.png`;

export function mapToObj(inputMap: ISetting[]) {
    const obj: SettingMap = {};

    inputMap.forEach(function (value, key) {
        obj[value.key] = value.value;
    });

    return obj;
}

export function getFeedbacks(
    feedbacks?: Review[],
): { fTotal: number; fCount: number; fAvg: number; feedbacks?: Review[] } {
    let total = 0;
    let count = 0;
    if (!feedbacks || feedbacks.length === 0) return { fTotal: 0, fCount: 0, fAvg: 0, feedbacks };
    feedbacks.forEach(fb => {
        // if (reviewers) fb.user = reviewers.find((usr) => usr._id === fb.createdBy);
        total += fb.ratingPrice ? fb.ratingPrice : 0;
        total += fb.ratingService ? fb.ratingService : 0;
        total += fb.ratingProduct ? fb.ratingProduct : 0;
        total += fb.ratingDelivery ? fb.ratingDelivery : 0;
        total += fb.ratingCommunication ? fb.ratingCommunication : 0;
        count += 1;
    });
    return { fTotal: total, fCount: count, fAvg: total / count, feedbacks };
}

export function getRateValue(rates: ICurrency, curRate: string) {
    if (Object.keys(rates).length > 0) return rates[curRate];
    return 1;
}

export function getCurrencySymbol(currency: string) {
    const a = 0;
    const symbol = a
        .toLocaleString('en', { style: 'currency', currency: currency })
        .replace(/\d+([,.]\d+)?/g, '')
        .trimEnd();
    return symbol === currency ? '' : symbol;
}

export function applyRate(
    rates: ICurrency,
    rateFrom: string | undefined | null,
    rateTo: string | undefined | null,
    value: number,
    isNum = false,
): number | string {
    if (!rateFrom || rateFrom === '' || rateFrom === null) rateFrom = 'USD';
    if (!rateTo || rateTo === '' || rateTo === null) rateTo = 'USD';
    try {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: rateTo,
            // currencyDisplay: 'symbol',
        });
        if (!value) return isNum ? 0.0 : formatter.format(0);
        if (rateTo === rateFrom) return isNum ? value : formatter.format(value);
        if (Object.keys(rates).length === 0) return isNum ? value : formatter.format(value);

        const usdRateFrom = rates[rateFrom];
        const usdRateTo = rates[rateTo];
        value = value * (usdRateTo / usdRateFrom);
        return isNum ? Math.round(value * 100) / 100 : formatter.format(value);
    } catch (err) {
        console.warn(err, rateFrom, rateTo);
    }
    return 0;
}

export const validFlags = [
    'ad',
    'andorra',
    'ae',
    'united arab emirates',
    'uae',
    'af',
    'afghanistan',
    'ag',
    'antigua',
    'ai',
    'anguilla',
    'al',
    'albania',
    'am',
    'armenia',
    'an',
    'netherlands antilles',
    'ao',
    'angola',
    'ar',
    'argentina',
    'as',
    'american samoa',
    'at',
    'austria',
    'au',
    'australia',
    'aw',
    'aruba',
    'ax',
    'aland islands',
    'az',
    'azerbaijan',
    'ba',
    'bosnia',
    'bb',
    'barbados',
    'bd',
    'bangladesh',
    'be',
    'belgium',
    'bf',
    'burkina faso',
    'bg',
    'bulgaria',
    'bh',
    'bahrain',
    'bi',
    'burundi',
    'bj',
    'benin',
    'bm',
    'bermuda',
    'bn',
    'brunei',
    'bo',
    'bolivia',
    'br',
    'brazil',
    'bs',
    'bahamas',
    'bt',
    'bhutan',
    'bv',
    'bouvet island',
    'bw',
    'botswana',
    'by',
    'belarus',
    'bz',
    'belize',
    'ca',
    'canada',
    'cc',
    'cocos islands',
    'cd',
    'congo',
    'cf',
    'central african republic',
    'cg',
    'congo brazzaville',
    'ch',
    'switzerland',
    'ci',
    'cote divoire',
    'ck',
    'cook islands',
    'cl',
    'chile',
    'cm',
    'cameroon',
    'cn',
    'china',
    'co',
    'colombia',
    'cr',
    'costa rica',
    'cs',
    'cu',
    'cuba',
    'cv',
    'cape verde',
    'cx',
    'christmas island',
    'cy',
    'cyprus',
    'cz',
    'czech republic',
    'de',
    'germany',
    'dj',
    'djibouti',
    'dk',
    'denmark',
    'dm',
    'dominica',
    'do',
    'dominican republic',
    'dz',
    'algeria',
    'ec',
    'ecuador',
    'ee',
    'estonia',
    'eg',
    'egypt',
    'eh',
    'western sahara',
    'er',
    'eritrea',
    'es',
    'spain',
    'et',
    'ethiopia',
    'eu',
    'european union',
    'fi',
    'finland',
    'fj',
    'fiji',
    'fk',
    'falkland islands',
    'fm',
    'micronesia',
    'fo',
    'faroe islands',
    'fr',
    'france',
    'ga',
    'gabon',
    'gb',
    'uk',
    'united kingdom',
    'gd',
    'grenada',
    'ge',
    'georgia',
    'gf',
    'french guiana',
    'gh',
    'ghana',
    'gi',
    'gibraltar',
    'gl',
    'greenland',
    'gm',
    'gambia',
    'gn',
    'guinea',
    'gp',
    'guadeloupe',
    'gq',
    'equatorial guinea',
    'gr',
    'greece',
    'gs',
    'sandwich islands',
    'gt',
    'guatemala',
    'gu',
    'guam',
    'gw',
    'guinea-bissau',
    'gy',
    'guyana',
    'hk',
    'hong kong',
    'hm',
    'heard island',
    'hn',
    'honduras',
    'hr',
    'croatia',
    'ht',
    'haiti',
    'hu',
    'hungary',
    'id',
    'indonesia',
    'ie',
    'ireland',
    'il',
    'israel',
    'in',
    'india',
    'io',
    'indian ocean territory',
    'iq',
    'iraq',
    'ir',
    'iran',
    'is',
    'iceland',
    'it',
    'italy',
    'jm',
    'jamaica',
    'jo',
    'jordan',
    'jp',
    'japan',
    'ke',
    'kenya',
    'kg',
    'kyrgyzstan',
    'kh',
    'cambodia',
    'ki',
    'kiribati',
    'km',
    'comoros',
    'kn',
    'saint kitts and nevis',
    'kp',
    'north korea',
    'kr',
    'south korea',
    'kw',
    'kuwait',
    'ky',
    'cayman islands',
    'kz',
    'kazakhstan',
    'la',
    'laos',
    'lb',
    'lebanon',
    'lc',
    'saint lucia',
    'li',
    'liechtenstein',
    'lk',
    'sri lanka',
    'lr',
    'liberia',
    'ls',
    'lesotho',
    'lt',
    'lithuania',
    'lu',
    'luxembourg',
    'lv',
    'latvia',
    'ly',
    'libya',
    'ma',
    'morocco',
    'mc',
    'monaco',
    'md',
    'moldova',
    'me',
    'montenegro',
    'mg',
    'madagascar',
    'mh',
    'marshall islands',
    'mk',
    'macedonia',
    'ml',
    'mali',
    'mm',
    'myanmar',
    'burma',
    'mn',
    'mongolia',
    'mo',
    'macau',
    'mp',
    'northern mariana islands',
    'mq',
    'martinique',
    'mr',
    'mauritania',
    'ms',
    'montserrat',
    'mt',
    'malta',
    'mu',
    'mauritius',
    'mv',
    'maldives',
    'mw',
    'malawi',
    'mx',
    'mexico',
    'my',
    'malaysia',
    'mz',
    'mozambique',
    'na',
    'namibia',
    'nc',
    'new caledonia',
    'ne',
    'niger',
    'nf',
    'norfolk island',
    'ng',
    'nigeria',
    'ni',
    'nicaragua',
    'nl',
    'netherlands',
    'no',
    'norway',
    'np',
    'nepal',
    'nr',
    'nauru',
    'nu',
    'niue',
    'nz',
    'new zealand',
    'om',
    'oman',
    'pa',
    'panama',
    'pe',
    'peru',
    'pf',
    'french polynesia',
    'pg',
    'new guinea',
    'ph',
    'philippines',
    'pk',
    'pakistan',
    'pl',
    'poland',
    'pm',
    'saint pierre',
    'pn',
    'pitcairn islands',
    'pr',
    'puerto rico',
    'ps',
    'palestine',
    'pt',
    'portugal',
    'pw',
    'palau',
    'py',
    'paraguay',
    'qa',
    'qatar',
    're',
    'reunion',
    'ro',
    'romania',
    'rs',
    'serbia',
    'ru',
    'russia',
    'rw',
    'rwanda',
    'sa',
    'saudi arabia',
    'sb',
    'solomon islands',
    'sc',
    'seychelles',
    'gb sct',
    'scotland',
    'sd',
    'sudan',
    'se',
    'sweden',
    'sg',
    'singapore',
    'sh',
    'saint helena',
    'si',
    'slovenia',
    'sj',
    'svalbard',
    'jan mayen',
    'sk',
    'slovakia',
    'sl',
    'sierra leone',
    'sm',
    'san marino',
    'sn',
    'senegal',
    'so',
    'somalia',
    'sr',
    'suriname',
    'st',
    'sao tome',
    'sv',
    'el salvador',
    'sy',
    'syria',
    'sz',
    'swaziland',
    'tc',
    'caicos islands',
    'td',
    'chad',
    'tf',
    'french territories',
    'tg',
    'togo',
    'th',
    'thailand',
    'tj',
    'tajikistan',
    'tk',
    'tokelau',
    'tl',
    'timorleste',
    'tm',
    'turkmenistan',
    'tn',
    'tunisia',
    'to',
    'tonga',
    'tr',
    'turkey',
    'tt',
    'trinidad',
    'tv',
    'tuvalu',
    'tw',
    'taiwan',
    'tz',
    'tanzania',
    'ua',
    'ukraine',
    'ug',
    'uganda',
    'um',
    'us minor islands',
    'us',
    'america',
    'united states',
    'uy',
    'uruguay',
    'uz',
    'uzbekistan',
    'va',
    'vatican city',
    'vc',
    'saint vincent',
    've',
    'venezuela',
    'vg',
    'british virgin islands',
    'vi',
    'us virgin islands',
    'vn',
    'vietnam',
    'vu',
    'vanuatu',
    'gb wls',
    'wales',
    'wf',
    'wallis and futuna',
    'ws',
    'samoa',
    'ye',
    'yemen',
    'yt',
    'mayotte',
    'za',
    'south africa',
    'zm',
    'zambia',
    'zw',
    'zimbabwe',
];

export function isAllowed(user: IUser, right: string) {
    if (user.role === 'SUPERADMIN' || !user.accessRights) return true;
    // else if (!this.props.user.accessRights) return true;
    switch (right) {
        case 'dashboard':
            return user.accessRights.dashboard !== 'hidden';
        case 'settings':
            return user.accessRights.settings !== 'hidden';
        case 'users':
            return user.accessRights.users !== 'hidden';
        case 'categories':
            return user.accessRights.categories !== 'hidden';
        case 'filters':
            return user.accessRights.filters !== 'hidden';
        case 'pageTemplates':
            return user.accessRights.pageTemplates !== 'hidden';
        case 'emailTemplates':
            return user.accessRights.emailTemplates !== 'hidden';
        case 'storeTypes':
            return user.accessRights.storeTypes !== 'hidden';
        case 'manageSurvey':
            return user.accessRights.manageSurvey !== 'hidden';
        case 'coupons':
            return user.accessRights.coupons !== 'hidden';
        case 'posts':
            return user.accessRights.posts !== 'hidden';
        case 'stores':
            return user.accessRights.stores !== 'hidden';
        case 'orders':
            return user.accessRights.orders !== 'hidden';

        default:
            return false;
    }
}

export const CATEGORIES_STORE_TYPES = [
    { name: 'Unsorted', val: 'UNSORT' },
    { name: 'One category + Sub', val: 'ONE_SUB' },
    { name: 'All', val: 'ALL' },
];

export const COUPON_TYPES = [
    { name: 'Unlimited', val: 'UNLIMITED' },
    { name: 'Limited by usages per user', val: 'LIMITED_PER_USER' },
    { name: 'Limited by max usages', val: 'LIMITED_MAX' },
];

export const EMAIL_TYPES = [
    {
        name: 'Q&A Notification',
        type: 'qa_notification',
        variables: [
            {
                placeholder: '%user%',
                value: 'User',
            },
            {
                placeholder: '%question%',
                value: 'Question',
            },
            {
                placeholder: '%answer%',
                value: 'Answer',
            },
            {
                placeholder: '%ad_url%',
                value: `https://test-beedit.tk/`,
            },
        ],
    },
    {
        name: 'User message',
        type: 'message',
        variables: [
            {
                placeholder: '%sender%',
                value: 'Sender',
            },
            {
                placeholder: '%message_text%',
                value: 'Message Text',
            },
            {
                placeholder: '%message_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/dashboard/messages`,
            },
        ],
    },
    {
        name: 'Registration Confirmation',
        type: 'confirmation',
        variables: [
            {
                placeholder: '%first_name%',
                value: 'Name',
            },
            {
                placeholder: '%last_name%',
                value: 'Last name',
            },
            {
                placeholder: '%username%',
                value: 'Username',
            },
            {
                placeholder: '%confirm_link%',
                value: `${process.env.REACT_APP_CLIENT_URL}/confirm/confirmation_key`,
            },
        ],
    },
    {
        name: 'Two-Step Code',
        type: 'twostepcode',
        variables: [
            {
                placeholder: '%first_name%',
                value: 'Name',
            },
            {
                placeholder: '%last_name%',
                value: 'Last name',
            },
            {
                placeholder: '%username%',
                value: 'Username',
            },
            {
                placeholder: '%code%',
                value: '123456',
            },
        ],
    },
    {
        name: 'Recover password',
        type: 'recover',
        variables: [
            {
                placeholder: '%first_name%',
                value: 'Name',
            },
            {
                placeholder: '%last_name%',
                value: 'Last name',
            },
            {
                placeholder: '%username%',
                value: 'Username',
            },
            {
                placeholder: '%recover_link%',
                value: `${process.env.REACT_APP_CLIENT_URL}/forgot-password/recover_key`,
            },
        ],
    },
    {
        name: 'Welcome Email',
        type: 'welcome',
        variables: [
            {
                placeholder: '%first_name%',
                value: 'Name',
            },
            {
                placeholder: '%last_name%',
                value: 'Last name',
            },
            {
                placeholder: '%username%',
                value: 'Username',
            },
        ],
    },
    {
        name: 'Store: Membership purchase/renew successfully',
        type: 'store_purchase',
        variables: [
            {
                placeholder: '%title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
        ],
    },
    {
        name: 'Store: Membership expiring soon (7 days and day to end)',
        type: 'store_expiring',
        variables: [
            {
                placeholder: '%title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
        ],
    },
    {
        name: 'Store: Membership Expired',
        type: 'store_expired',
        variables: [
            {
                placeholder: '%title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
        ],
    },
    {
        name: 'Store: Post Added',
        type: 'store_post_added',
        variables: [
            {
                placeholder: '%st_title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
            {
                placeholder: '%img_url%',
                value: 'Image',
            },
        ],
    },
    {
        name: 'Store: Plan changed',
        type: 'store_plan_changed',
        variables: [
            {
                placeholder: '%title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
        ],
    },
    {
        name: 'Store: Report all posts on store',
        type: 'store_posts_report',
        variables: [
            {
                placeholder: '%st_title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%expires_on%',
                value: 'expires_on',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
            {
                placeholder: '%ads%',
                value:
                    '<table><tbody><tr><td>ID:</td><td>Title</td><td>Short descr</td><td>Max price</td><td>Min Price</td></tr></tbody></table>',
            },
        ],
    },
    {
        name: 'Store: Empty store notification',
        type: 'store_empty',
        variables: [
            {
                placeholder: '%title%',
                value: 'Store name',
            },
            {
                placeholder: '%store_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/stores`,
            },
            {
                placeholder: '%days%',
                value: '30',
            },
            {
                placeholder: '%paid%',
                value: '$99.99',
            },
            {
                placeholder: '%logo%',
                value: 'logo',
            },
            {
                placeholder: '%store_type%',
                value: 'Garage Sale',
            },
        ],
    },
    {
        name: 'Post published successfully',
        type: 'ad_posted',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
            {
                placeholder: '%img_url%',
                value: 'Image',
            },
        ],
    },
    {
        name: 'Post deleted',
        type: 'ad_deleted',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
        ],
    },
    {
        name: 'Post deleted after extra 30 days',
        type: 'ad_deleted_30',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
        ],
    },
    {
        name: 'Deadline post deleted',
        type: 'ad_deleted_deadline',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
        ],
    },
    {
        name: 'Ad expired',
        type: 'ad_expired',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%long_description%',
                value: 'Long description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%deposit_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%total_fee%',
                value: '$9.99',
            },
            {
                placeholder: '%taxes%',
                value: '$9.99',
            },
        ],
    },
    {
        name: 'Bid received on your ad',
        type: 'bid_received',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%bidder%',
                value: 'Bidder username',
            },
            {
                placeholder: '%bid_amount%',
                value: '$2.50',
            },
        ],
    },
    {
        name: 'Bid place and balance deduction',
        type: 'bid_placed',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%bidder%',
                value: 'Bidder username',
            },
            {
                placeholder: '%bid_amount%',
                value: '$2.50',
            },
        ],
    },
    {
        name: 'Bid winning alert',
        type: 'bid_winning',
        variables: [
            {
                placeholder: '%title%',
                value: 'Ad title',
            },
            {
                placeholder: '%ad_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}`,
            },
            {
                placeholder: '%min_price%',
                value: '$1.99',
            },
            {
                placeholder: '%max_price%',
                value: '$99.99',
            },
            {
                placeholder: '%beed_time%',
                value: '10 days 2 hours 15 minutes 12 seconds',
            },
            {
                placeholder: '%period_time%',
                value: '30 days',
            },
            {
                placeholder: '%short_description%',
                value: 'Short description',
            },
            {
                placeholder: '%category%',
                value: 'Main category > Sub category > Section',
            },
            {
                placeholder: '%bidder%',
                value: 'Bidder username',
            },
            {
                placeholder: '%bid_amount%',
                value: '$2.50',
            },
        ],
    },
    {
        name: 'Fund deposition',
        type: 'fund_deposit',
        variables: [
            {
                placeholder: '%username%',
                value: 'Username',
            },
            {
                placeholder: '%amount%',
                value: '$2.50',
            },
            {
                placeholder: '%balance%',
                value: '$2.50',
            },
            {
                placeholder: '%balance_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/dashboard/balance`,
            },
        ],
    },
    {
        name: 'Fund/Comission refund',
        type: 'fund_refund',
        variables: [
            {
                placeholder: '%username%',
                value: 'Username',
            },
            {
                placeholder: '%amount%',
                value: '$2.50',
            },
            {
                placeholder: '%balance%',
                value: '$2.50',
            },
            {
                placeholder: '%balance_url%',
                value: `${process.env.REACT_APP_CLIENT_URL}/dashboard/balance`,
            },
        ],
    },
];

export const COUNTRIES_OPTIONS: [] = CountryRegionData.map((country: any) => {
    return { label: country[0], value: country[0] };
});

export const EFFECT_ANIMATIONS = ['fallingSnow', 'sparkle1', 'flash', 'glitter'];

export const GOOGLE_CHARTS = {
    sessions10: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:date',
            metrics: 'ga:sessions',
            'start-date': '10daysAgo',
            'end-date': 'yesterday',
        },
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 10 days sessions',
                width: '100%',
            },
        },
    },
    sessions30: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:date',
            metrics: 'ga:sessions',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
        },
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 30 days sessions',
                width: '100%',
            },
        },
    },
    pageViews30: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:date',
            metrics: 'ga:pageviews',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
        },
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 30 days pageviews',
                width: '100%',
            },
        },
    },
    sessionTime: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:date',
            metrics: 'ga:avgSessionDuration',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
        },
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 30 days session average in seconds',
                width: '100%',
            },
        },
    },
    avgTimeOnPage: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:pageTitle',
            metrics: 'ga:avgTimeOnPage',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
        },
        dimensions: [
            {
                name: 'ga:nthMinute',
            },
        ],
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 30 days average time spent on page',
                width: '100%',
            },
        },
    },
    avgPageviews: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:date',
            metrics: 'ga:pageviews',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
        },
        chart: {
            type: 'LINE',
            options: {
                title: 'Last 30 days total pageviews per 1 session',
                width: '100%',
            },
        },
    },
    sessionCountry: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:country',
            metrics: 'ga:sessions',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
            'max-results': 6,
            sort: '-ga:sessions',
        },
        chart: {
            type: 'PIE',
            options: {
                title: 'Sessions by countries over past 30 days',
                width: '100%',
                pieHole: 4 / 9,
            },
        },
    },
    sessionBrowser: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:browser',
            metrics: 'ga:sessions',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
            'max-results': 6,
            sort: '-ga:sessions',
        },
        chart: {
            type: 'PIE',
            options: {
                title: 'Top browsers over past 30 days',
                width: '100%',
                pieHole: 4 / 9,
            },
        },
    },
    sessionDevices: {
        reportType: 'ga',
        query: {
            dimensions: 'ga:deviceCategory',
            metrics: 'ga:sessions',
            'start-date': '30daysAgo',
            'end-date': 'yesterday',
            'max-results': 6,
            sort: '-ga:sessions',
        },
        chart: {
            type: 'PIE',
            options: {
                title: 'Devices over past 30 days',
                width: '100%',
                pieHole: 4 / 9,
            },
        },
    },
};

export type DashboardMode = 'users' | 'analytics' | 'demo' | 'finances' | 'post_ads' | 'stores' | 'orders' | 'expenses';

export type DashboardSubMode =
    | 'total'
    | 'private'
    | 'business'
    | 'active'
    | 'visitors'
    | 'online'
    | 'deposit'
    | 'not_deposit'
    | 'by_region'
    | 'by_balance'
    | 'buying'
    | 'selling'
    | 'commission_payed'
    | 'by_date'
    | 'settings'
    | 'passwords'
    | 'total_income'
    | 'total_income_beeditup'
    | 'total_deposits'
    | 'total_posts_income'
    | 'total_effect_income'
    | 'total_frame_income'
    | 'total_commission_income_seller'
    | 'total_commission_income_buyer'
    | 'total_stores_income'
    | 'total_private_income'
    | 'total_business_income'
    | 'users_balances'
    | 'users_income'
    | 'expense_total'
    | 'expense_commission'
    | 'expense_refunds'
    | 'expense_promotion'
    | 'expense_marketing'
    | 'expense_develop'
    | 'expense_hosting'
    | 'expense_admin'
    | 'expense_hr'
    | 'categories'
    | 'prices'
    | 'bulk'
    | 'about_to_end'
    | 'about_to_expire'
    | 'ended'
    | 'less_30'
    | 'more_30'
    | 'freebee'
    | 'external'
    | 'types'
    | 'posts'
    | 'success'
    | 'failed'
    | 'freebee_total'
    | 'freebee_about_to_end'
    | 'freebee_about_to_expire'
    | 'freebee_ended';

export function formatMode(mode: string) {
    if (!mode) return '-';
    return (mode.charAt(0).toUpperCase() + mode.slice(1)).split('_').join(' ');
}

export const STATIC_RANGES = createStaticRanges([
    {
        label: 'Today',
        range: () => ({
            startDate: moment()
                .startOf('day')
                .toDate(),
            endDate: moment()
                .endOf('day')
                .toDate(),
        }),
    },
    {
        label: 'Yesterday',
        range: () => ({
            startDate: moment()
                .add(-1, 'day')
                .startOf('day')
                .toDate(),
            endDate: moment()
                .add(-1, 'day')
                .endOf('day')
                .toDate(),
        }),
    },

    {
        label: 'This Week',
        range: () => ({
            startDate: moment()
                .startOf('week')
                .toDate(),
            endDate: moment()
                .endOf('week')
                .toDate(),
        }),
    },
    {
        label: 'Last Week',
        range: () => ({
            startDate: moment()
                .add(-1, 'week')
                .startOf('week')
                .toDate(),
            endDate: moment()
                .add(-1, 'week')
                .endOf('week')
                .toDate(),
        }),
    },
    {
        label: 'This Month',
        range: () => ({
            startDate: moment()
                .startOf('month')
                .toDate(),
            endDate: moment()
                .endOf('month')
                .toDate(),
        }),
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: moment()
                .add(-1, 'month')
                .startOf('month')
                .toDate(),
            endDate: moment()
                .add(-1, 'month')
                .endOf('month')
                .toDate(),
        }),
    },
    {
        label: 'This Year',
        range: () => ({
            startDate: moment()
                .startOf('year')
                .toDate(),
            endDate: moment()
                .endOf('year')
                .toDate(),
        }),
    },
    {
        label: 'Last Year',
        range: () => ({
            startDate: moment()
                .add(-1, 'year')
                .startOf('year')
                .toDate(),
            endDate: moment()
                .add(-1, 'year')
                .endOf('year')
                .toDate(),
        }),
    },
    {
        label: 'Quarter 1',
        range: () => ({
            startDate: moment()
                .quarter(1)
                .startOf('quarter')
                .toDate(),
            endDate: moment()
                .quarter(1)
                .endOf('quarter')
                .toDate(),
        }),
    },
    {
        label: 'Quarter 2',
        range: () => ({
            startDate: moment()
                .quarter(2)
                .startOf('quarter')
                .toDate(),
            endDate: moment()
                .quarter(2)
                .endOf('quarter')
                .toDate(),
        }),
    },
    {
        label: 'Quarter 3',
        range: () => ({
            startDate: moment()
                .quarter(3)
                .startOf('quarter')
                .toDate(),
            endDate: moment()
                .quarter(3)
                .endOf('quarter')
                .toDate(),
        }),
    },
    {
        label: 'Quarter 4',
        range: () => ({
            startDate: moment()
                .quarter(4)
                .startOf('quarter')
                .toDate(),
            endDate: moment()
                .quarter(4)
                .endOf('quarter')
                .toDate(),
        }),
    },
]);

export function expensePeriod(exp: Expense) {
    const period = ` [${exp.expensePeriod.toLocaleUpperCase()}]`;
    switch (exp.expensePeriod) {
        case ExpensePeriodType.DAILY:
            return moment(exp.periodFrom).format('dddd, MMMM Do YYYY') + period;
        case ExpensePeriodType.WEEKLY:
            return moment(exp.periodFrom).format('ww [week], MMMM YYYY') + period;
        case ExpensePeriodType.MONTHLY:
            return moment(exp.periodFrom).format('MMMM, YYYY') + period;
        case ExpensePeriodType.QUARTERLY:
            return moment(exp.periodFrom).format('Q [quarter], YYYY') + period;
        case ExpensePeriodType.YEARLY:
            return moment(exp.periodFrom).format('YYYY') + period;

        default:
            return moment(exp.periodFrom).format('dddd, MMMM Do YYYY');
    }
}

export function setExpensePeriods(exp: Expense, date: Date) {
    switch (exp.expensePeriod) {
        case ExpensePeriodType.DAILY: {
            exp.periodFrom = date;
            exp.periodTo = date;
            break;
        }
        case ExpensePeriodType.WEEKLY: {
            exp.periodFrom = moment(date)
                .startOf('week')
                .toDate();
            exp.periodTo = moment(date)
                .endOf('week')
                .toDate();
            break;
        }
        case ExpensePeriodType.MONTHLY: {
            exp.periodFrom = moment(date)
                .startOf('month')
                .toDate();
            exp.periodTo = moment(date)
                .endOf('month')
                .toDate();
            break;
        }
        case ExpensePeriodType.QUARTERLY: {
            exp.periodFrom = moment(date)
                .startOf('quarter')
                .toDate();
            exp.periodTo = moment(date)
                .endOf('quarter')
                .toDate();
            break;
        }
        case ExpensePeriodType.YEARLY: {
            exp.periodFrom = moment(date)
                .startOf('year')
                .toDate();
            exp.periodTo = moment(date)
                .endOf('year')
                .toDate();
            break;
        }
        default: {
            exp.periodFrom = date;
            exp.periodTo = date;
            break;
        }
    }
    return exp;
}

export function generateCode(count: number) {
    return (+new Date())
        .toString(36)
        .slice(-count)
        .toLocaleUpperCase();
}

function getCategoryCode(categories: ICategory[], cat_main: string, sub_cat: string, sub_cat_section: string) {
    let cat1 = categories.find(cat => cat.id === cat_main);
    let cat2 = categories.find(cat => cat.id === sub_cat);
    let cat3 = categories.find(cat => cat.id === sub_cat_section);

    const need2Or1 = cat2 === undefined;
    if (!cat1) return '?';
    let result = cat1.title_en.substring(0, need2Or1 ? 2 : 1);
    if (cat2) result += cat2.title_en.substring(0, 1);
    if (cat3) result += cat3.title_en.substring(0, 1);
    return result.toLocaleUpperCase();
}

export function generatePostId(post: IPost, categories: ICategory[]) {
    const { cat_main, status, createdAt, deadline } = post;
    let cat1 = categories.find(cat => cat.id === cat_main);
    const categoryCode = cat1 ? cat1.title_en.substring(0, 2).toLocaleUpperCase() : '?';
    const createDate = moment(createdAt).format('DDMMYY');
    const isDeleted = status === 'deleted';
    const isExpired = moment(deadline).isSameOrBefore(moment());
    return `${post.is_external ? 'X_' : ''}${categoryCode}_${createDate}_${post._id}${post.is_bulk ? '_bp' : ''}${
        isDeleted ? '_dl' : isExpired ? '_ex' : ''
    }`;
}

export function generateStoreId(store: IStore, categories: ICategory[]) {
    const { cat_main, createdAt } = store;
    let cat1 = categories.find(cat => cat.id === cat_main);
    const categoryCode = cat1 ? cat1.title_en.substring(0, 2).toLocaleUpperCase() : '';
    const createDate = moment(createdAt).format('DDMMYY');
    // const isDeleted = status === 'deleted';
    // const isExpired = moment(deadline).isSameOrBefore(moment());
    return `${categoryCode}${createDate}_${store._id}`;
}

export function generateOrderId(order: Order) {
    const { post, client, seller, finalTxs, _id } = order;

    const createDate = moment(post.deadline).format('DDMMYY');
    const isPayed = finalTxs.length === 1;
    const isRefunded = finalTxs.length > 0;
    // const isExpired = moment(deadline).isSameOrBefore(moment());
    return `${order.post.is_external ? 'X_' : ''}${isPayed ? 'PY' : 'UP'}_${createDate}_${order._id}${
        isRefunded ? '_RF' : ''
    }`;
}

export function generateActionId(post: IPost, categories: ICategory[], action: PostHistoryAction) {
    const { cat_main, sub_cat, sub_cat_section, deadline, is_bulk } = post;
    let cat1 = categories.find(cat => cat.id === cat_main);
    const categoryCode = cat1 ? cat1.title_en.substring(0, 2).toLocaleUpperCase() : '?';
    const orderDate = moment(deadline).format('DDMMYY');
    let actionCode = '';
    switch (action.action) {
        case PostHistoryActionType.BID_WON:
            actionCode = 'bw';
            break;
        case PostHistoryActionType.CREATED:
            actionCode = 'cr';
            break;
        case PostHistoryActionType.DELETED:
            actionCode = 'dl';
            break;
        case PostHistoryActionType.DEPOSIT_REFUNDED:
            actionCode = 'dr';
            break;
        case PostHistoryActionType.EXPIRED:
            actionCode = 'ex';
            break;
        case PostHistoryActionType.NEW_BID:
            actionCode = 'bd';
            break;
        case PostHistoryActionType.NEW_TP_BID:
            actionCode = 'tp';
            break;
        case PostHistoryActionType.ORDER_PAYED:
            actionCode = 'op';
            break;
        case PostHistoryActionType.ORDER_REFUNDED:
            actionCode = 'or';
            break;
        case PostHistoryActionType.PAID_ADDPOST:
            actionCode = 'ad';
            break;
        case PostHistoryActionType.PAID_BULK:
            actionCode = 'bp';
            break;
        case PostHistoryActionType.POST_EDITED:
            actionCode = 'ed';
            break;
        case PostHistoryActionType.SELLER_DEPOSIT:
            actionCode = 'de';
            break;

        default:
            break;
    }
    return `${post.is_external ? 'X_' : ''}${categoryCode}_${orderDate}_${post._id}_${actionCode}`;
}

export function generateOrderActionId(order: Order, action: OrderHistoryAction) {
    const orderID = generateOrderId(order);
    // let cat1 = categories.find(cat => cat.id === cat_main);
    // const categoryCode = cat1 ? cat1.title_en.substring(0, 2).toLocaleUpperCase() : '?';
    // const orderDate = moment(deadline).format('DDMMYY');
    let actionCode = '';
    switch (action.action) {
        case OrderHistoryActionType.ADDED:
            actionCode = 'ad';
            break;
        case OrderHistoryActionType.SELLER_FINAL_FEE:
            actionCode = 'sf';
            break;
        case OrderHistoryActionType.BUYER_FINAL_FEE:
            actionCode = 'bf';
            break;
        case OrderHistoryActionType.PAID:
            actionCode = 'pd';
            break;
        case OrderHistoryActionType.REFUNDED:
            actionCode = 'rf';
            break;
        case OrderHistoryActionType.MESSAGE_SENT:
            actionCode = 'ms';
            break;
        case OrderHistoryActionType.ADMIN_REFUND:
            actionCode = 'ar';
            break;

        default:
            break;
    }
    return `${orderID}_${actionCode}`;
}

export function generateStoreActionId(store: IStore, categories: ICategory[], action: StoreHistoryAction) {
    const { cat_main, createdAt } = store;
    let cat1 = categories.find(cat => cat.id === cat_main);
    const categoryCode = cat1 ? cat1.title_en.substring(0, 2).toLocaleUpperCase() : '?';
    const createDate = moment(createdAt).format('DDMMYY');
    let actionCode = '';
    switch (action.action) {
        case StoreHistoryActionType.ADMIN_REFUND:
            actionCode = 'ar';
            break;
        case StoreHistoryActionType.CREATED:
            actionCode = 'cr';
            break;
        case StoreHistoryActionType.DELETED:
            actionCode = 'dl';
            break;
        case StoreHistoryActionType.STORE_EDITED:
            actionCode = 'ed';
            break;
        case StoreHistoryActionType.EXPIRED:
            actionCode = 'ex';
            break;
        case StoreHistoryActionType.NEW_POST:
            actionCode = 'np';
            break;

        default:
            break;
    }
    return `${categoryCode}_${createDate}_${store._id}_${actionCode}`;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function formatMoney(amount: number | null): string {
    if (amount === null) return '-';
    return formatter.format(amount);
}

export function calculateDepositFee(
    fee_table: Fee[],
    fixed: {
        fee_table_fixed_down?: Fee;
        fee_table_fixed_up?: Fee;
        fee_table_beedit_up_fixed_down?: Fee;
        fee_table_beedit_up_fixed_up?: Fee;
        external_fee_table_fixed_down?: ExternalFeeEntry;
        external_fee_table_fixed_up?: ExternalFeeEntry;
    },
    fee_threshold: number,
    freebee_fixed_fee: number,
    postPrice: { min: number; max: number },
    isFreeBee = false,
    beeditUp = false,
    type: 'buyer' | 'seller' | 'partner' | 'pb user' | 'travel' | 'pb travel',
    isApi = false,
): {
    feePerc: number | undefined;
    price: number;
    deposit: number;
    maxDeposit: number;
    percFrom?: number;
    percTo?: number;
    minFee?: number;
    maxFee?: number;
    freeBeeFixedFee?: number;
    rowFromIndex?: number;
    rowToIndex?: number;
    isFixedDown?: boolean;
    isFixedUp?: boolean;
} {
    const depositFee = 0;
    let maxDeposit = fee_threshold;
    let freeBeeFixedFee = freebee_fixed_fee;
    let fees: Fee[] = fee_table;

    let rowFromIndex = undefined;
    let rowToIndex = undefined;

    // if (settings['fee_table']) {
    //     fees = JSON.parse(settings['fee_table']);
    // }
    // if (settings['fee_threshold']) {
    //     maxDeposit = parseInt(settings['fee_threshold']);
    // }
    // if (settings['freebee_fixed_fee'] && isFreeBee) {
    //     freeBeeFixedFee = parseFloat(settings['freebee_fixed_fee']);
    // }

    let firstFeeStep: Fee | undefined;
    let curFeeStep: Fee | undefined;
    let prevFeeStep: Fee | undefined;
    // let nextFeeStep: Fee | undefined;
    let isLast = false;
    for (let index = 0; index < fees.length; index++) {
        const step = fees[index];
        if (!firstFeeStep && step) firstFeeStep = step;
        if (step.from <= postPrice.max) {
            curFeeStep = step;
            rowToIndex = index;
            if (index < fees.length - 1) {
                prevFeeStep = index > 0 ? fees[index - 1] : firstFeeStep;
                rowFromIndex = index - 1;
                // nextFeeStep = fees[index + 1];
            }
            if (index === fees.length - 1) isLast = true;
        }
    }
    if (!curFeeStep) {
        if (fixed.fee_table_fixed_down && fixed.fee_table_beedit_up_fixed_down) {
            let fixedPrice = 0;
            if (type === 'buyer')
                fixedPrice = !beeditUp ? fixed.fee_table_fixed_down.buyer : fixed.fee_table_beedit_up_fixed_down.buyer;
            if (type === 'seller')
                fixedPrice = !beeditUp
                    ? fixed.fee_table_fixed_down.seller_perc
                    : fixed.fee_table_beedit_up_fixed_down.seller_perc;
            if (type === 'partner')
                fixedPrice = !beeditUp
                    ? fixed.fee_table_fixed_down.partner_perc
                    : fixed.fee_table_beedit_up_fixed_down.partner_perc;
            if (type === 'pb user')
                fixedPrice = !beeditUp
                    ? fixed.fee_table_fixed_down.pb_user_perc
                    : fixed.fee_table_beedit_up_fixed_down.pb_user_perc;
            if (type === 'travel')
                fixedPrice = !beeditUp
                    ? fixed.fee_table_fixed_down.travel_perc
                    : fixed.fee_table_beedit_up_fixed_down.travel_perc;
            if (type === 'pb travel')
                fixedPrice = !beeditUp
                    ? fixed.fee_table_fixed_down.pb_travel_perc
                    : fixed.fee_table_beedit_up_fixed_down.pb_travel_perc;
            return {
                feePerc: undefined,
                price: fixedPrice,
                deposit: fixedPrice,
                maxDeposit,
                isFixedDown: true,
            };
        }
        return { feePerc: 0, price: 0, deposit: 0, maxDeposit };
    }
    if (type === 'buyer') {
        let buyerDeposit = 0;
        if (curFeeStep && prevFeeStep) {
            buyerDeposit = isFreeBee ? freeBeeFixedFee : (curFeeStep.buyer + prevFeeStep.buyer) / 2;
            if (isLast) {
                if (fixed.fee_table_fixed_up && fixed.fee_table_beedit_up_fixed_up) {
                    let fixedPrice = !beeditUp
                        ? fixed.fee_table_fixed_up.buyer
                        : fixed.fee_table_beedit_up_fixed_up.buyer;

                    return {
                        feePerc: undefined,
                        price: fixedPrice,
                        deposit: fixedPrice,
                        maxDeposit,
                        isFixedUp: true,
                    };
                }
            }
            // if (buyerDeposit > maxDeposit) buyerDeposit = maxDeposit;
        }
        return { feePerc: undefined, price: buyerDeposit, deposit: buyerDeposit, maxDeposit, freeBeeFixedFee };
    }
    if (
        (type === 'seller' || type === 'partner' || type === 'pb user' || type === 'travel' || type === 'pb travel') &&
        firstFeeStep
    ) {
        const priceFrom = curFeeStep.from;
        const priceTo = 0;
        let percFrom = 0;
        let percTo =
            type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
        if (type === 'pb user') percTo = firstFeeStep.pb_user_perc;

        if (type === 'travel') percTo = firstFeeStep.travel_perc;
        if (type === 'pb travel') percTo = firstFeeStep.pb_travel_perc;

        if (curFeeStep.from === 0) {
            percFrom =
                type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = firstFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = firstFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = firstFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = firstFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo !== percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                percTo = percFrom - percOfRange;
            }

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            return {
                feePerc: percTo,
                price: postPrice.max * (depositFee / 100),
                deposit: maxFee,
                percFrom,
                percTo,
                minFee,
                maxFee,
                maxDeposit,
                rowFromIndex,
                rowToIndex,
            };
        }
        if (prevFeeStep && curFeeStep) {
            percFrom =
                type === 'partner' && prevFeeStep.partner_perc ? prevFeeStep.partner_perc : prevFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = prevFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = prevFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = prevFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = prevFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo !== percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                if (percOfRange < percFrom) percTo = percFrom - percOfRange;
                else {
                    // Calculate fixed prices over last!
                    if (fixed.fee_table_fixed_up && fixed.fee_table_beedit_up_fixed_up) {
                        let fixedPrice = 0;
                        // if (type === 'buyer')
                        //     fixedPrice = !beeditUp ? fixed.fee_table_fixed_down.buyer : fixed.fee_table_beedit_up_fixed_down.buyer;
                        if (type === 'seller')
                            fixedPrice = !beeditUp
                                ? fixed.fee_table_fixed_up.seller_perc
                                : fixed.fee_table_beedit_up_fixed_up.seller_perc;
                        if (type === 'partner')
                            fixedPrice = !beeditUp
                                ? fixed.fee_table_fixed_up.partner_perc
                                : fixed.fee_table_beedit_up_fixed_up.partner_perc;
                        if (type === 'pb user')
                            fixedPrice = !beeditUp
                                ? fixed.fee_table_fixed_up.pb_user_perc
                                : fixed.fee_table_beedit_up_fixed_up.pb_user_perc;
                        if (type === 'travel')
                            fixedPrice = !beeditUp
                                ? fixed.fee_table_fixed_up.travel_perc
                                : fixed.fee_table_beedit_up_fixed_up.travel_perc;
                        if (type === 'pb travel')
                            fixedPrice = !beeditUp
                                ? fixed.fee_table_fixed_up.pb_travel_perc
                                : fixed.fee_table_beedit_up_fixed_up.pb_travel_perc;
                        return {
                            feePerc: undefined,
                            price: fixedPrice,
                            deposit: fixedPrice,
                            maxDeposit,
                            // rowFromIndex,
                            // rowToIndex,
                            isFixedUp: true,
                        };
                    }
                }
            }

            // console.log({ percTo, percFrom, rangePrice, correctedStartValue });

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            const sellerDeposit = maxFee;
            if (curFeeStep.from === priceTo)
                return {
                    feePerc: percFrom,
                    price: postPrice.max * (percFrom / 100),
                    deposit: sellerDeposit,
                    percFrom,
                    percTo,
                    minFee: minFee,
                    maxFee: maxFee,
                    maxDeposit,
                    rowFromIndex,
                    rowToIndex,
                };
        } else {
            percFrom =
                type === 'partner' && firstFeeStep.partner_perc ? firstFeeStep.partner_perc : firstFeeStep.seller_perc;
            percTo = type === 'partner' && curFeeStep.partner_perc ? curFeeStep.partner_perc : curFeeStep.seller_perc;
            if (type === 'pb user') percFrom = firstFeeStep.pb_user_perc;
            if (type === 'pb user') percTo = curFeeStep.pb_user_perc;

            if (type === 'travel') percFrom = firstFeeStep.travel_perc;
            if (type === 'travel') percTo = curFeeStep.travel_perc;
            if (type === 'pb travel') percFrom = firstFeeStep.pb_travel_perc;
            if (type === 'pb travel') percTo = curFeeStep.pb_travel_perc;

            const curPriceFrom = firstFeeStep.from;
            const curPriceTo = curFeeStep.from;

            const rangePrice = curPriceTo - curPriceFrom;
            const correctedStartValue = postPrice.max - curPriceFrom;
            const percPrice = correctedStartValue / rangePrice;

            if (percTo === percFrom) {
                const rangePerc = percFrom - percTo;
                const percOfRange = percPrice * rangePerc;
                // console.log({
                //     curPriceFrom,
                //     curPriceTo,
                //     percTo,
                //     percFrom,
                //     percPrice,
                //     percOfRange,
                //     rangePrice,
                //     correctedStartValue,
                // });
                percTo = percFrom - percOfRange;
            }

            const minFee = postPrice.min * (percFrom / 100);
            const maxFee = postPrice.max * (percTo / 100);
            const sellerDeposit = maxFee;
            return {
                feePerc: percTo,
                price: postPrice.max * (depositFee / 100),
                deposit: sellerDeposit,
                percFrom,
                percTo,
                minFee,
                maxFee,
                maxDeposit,
                rowFromIndex,
                rowToIndex,
            };
        }
        let feePerc = 0;
        const rangePrice = priceTo - priceFrom;
        const correctedStartValue = postPrice.max - priceFrom;
        const percPrice = correctedStartValue / rangePrice;

        const rangePerc = percTo - percFrom;
        const percOfRange = percPrice * rangePerc;
        feePerc = percOfRange + percFrom;

        const minFee = postPrice.min * (percFrom / 100);
        const maxFee = postPrice.max * (percTo / 100);
        const sellerDeposit = maxFee;

        return {
            feePerc: feePerc,
            price: postPrice.max * (feePerc / 100),
            deposit: sellerDeposit,
            percFrom,
            percTo,
            minFee: minFee,
            maxFee: maxFee,
            maxDeposit,
            rowFromIndex,
            rowToIndex,
        };
    }
    console.log('WRONG CALCULATIONS!');
    return { feePerc: undefined, price: 0, deposit: 0, maxDeposit };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
