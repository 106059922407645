import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { SurveyActionTypes } from './types';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

import { toast } from 'react-toastify';

// import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { Survey } from '../../types/Survey';
import { ICategory } from '../../types/Category';
import { fetchCategories } from '../category/actions';
import { fetchError, onFetchedSurveys, updateSurvey, fetchSurveys, removeSurvey, addSurvey } from './actions';
import { IPost } from '../../types/Post';
import { fetchPosts } from '../post/actions';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getCategories = (state: ApplicationState) => state.category.allCategories;
export const getPosts = (state: ApplicationState) => state.post.posts;

function* handleFetchAll() {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const categories: ICategory[] = yield select(getCategories);
        if (!categories || categories.length === 0) yield put(fetchCategories());

        const posts: IPost[] = yield select(getPosts);
        if (!posts || posts.length === 0) yield put(fetchPosts());

        let allSurveys: Survey[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/survey/', token);

        if (categories.length === 0 || (posts.length === 0 && allSurveys.length > 0)) {
            yield put(fetchSurveys());
        } else {
            if (allSurveys.error) {
                // console.log(allSurveys)
                yield put(fetchError('Error fetching surveys'));
                toast('Error fetching surveys', { type: toast.TYPE.ERROR });
            } else {
                let allSurveysRes = allSurveys.map(ft => {
                    ft.involved = ft.involved.map(inv => {
                        let cat = categories.find(ct => ct.id === inv.categoryId);
                        if (cat) inv.category = cat;
                        inv.participators = inv.participators.map(ptc => {
                            let post = posts.find(pst => pst._id === ptc.post_id);
                            if (post) ptc.post = post;
                            return ptc;
                        });
                        return inv;
                    });

                    return ft;
                });
                // console.log("fetched surveys", allSurveysRes, posts)
                yield put(onFetchedSurveys(allSurveysRes));
            }
        }
    } catch (err) {
        console.error(err);
        if (err instanceof Error) {
            // // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdate(action: ReturnType<typeof updateSurvey>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        let obj = Object.assign({}, action.payload as any);
        // obj = obj.involved.map((inv: any)=>{
        //     delete inv.category;
        //     inv.participators = inv.participators.map((part: any)=>{
        //         delete part.post
        //         return part
        //     })
        //     return inv;
        // })
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/survey/${action.payload.id}`,
            token,
            obj,
        );

        // console.log(res)
        if (res.error) {
            // console.log(res)
            yield put(fetchError('Error updating filter'));
            toast('Error updating filter', { type: toast.TYPE.ERROR });
        } else {
            yield put(fetchSurveys());
        }
    } catch (err) {
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemove(action: ReturnType<typeof removeSurvey>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/survey/${action.payload}`,
            token,
        );

        // console.log(res)
        if (res.error) {
            // console.log(res)
            yield put(fetchError('Error removing filter'));
            toast('Error removing filter', { type: toast.TYPE.ERROR });
        } else {
            toast('Filter removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchSurveys());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        // console.log(err);
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAdd(action: ReturnType<typeof addSurvey>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        let obj = Object.assign({}, action.payload as any);
        // obj = obj.involved.map((inv: any)=>{
        //     delete inv.category;
        //     inv.participators = inv.participators.map((part: any)=>{
        //         delete part.post
        //         return part
        //     })
        //     return inv;
        // })
        const res: ApiResponse & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/survey/`, token, obj);

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchSurveys());
        }
    } catch (err) {
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* watchUpdate() {
    yield takeEvery(SurveyActionTypes.UPDATE, handleUpdate);
}
function* watchFetchAll() {
    yield takeEvery(SurveyActionTypes.FETCHALL, handleFetchAll);
}
function* watchAdd() {
    yield takeEvery(SurveyActionTypes.ADD, handleAdd);
}
function* watchRemove() {
    yield takeEvery(SurveyActionTypes.REMOVE, handleRemove);
}

export function* surveySaga() {
    yield all([fork(watchUpdate), fork(watchFetchAll), fork(watchAdd), fork(watchRemove)]);
}
