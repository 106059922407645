import { IPost } from './Post';
import { IUser } from './User';

export interface IStoreType {
    id: string;
    name: string;
    membership_price: number;
    membership_days: number;
    annual_free: number;
    advantage_info_en: string;
    advantage_info_he: string;
    url_path: string;
    can_edit_info: boolean;
    can_edit_logo: boolean;
    can_edit_banner: boolean;
    can_edit_background: boolean;
    can_edit_colors: boolean;
    isEnabled: boolean;
    categories_type: string;
    url_slug: string;
    is_free: boolean;
    free_days: number;
    lock_days: number;
}

export enum StoreHistoryActionType {
    CREATED = 'created',
    STORE_EDITED = 'post_edited',
    PAID = 'paid',
    DELETED = 'deleted',
    EXPIRED = 'expired',
    NEW_POST = 'new_post',
    ADMIN_REFUND = 'admin_refund',
}

export interface StoreHistoryAction {
    id: string;
    action: StoreHistoryActionType;
    date: Date;
    source: JSX.Element | string;
    description: JSX.Element | string;
}

export interface StoreEditRecord {
    store: IStore;
    editDate: Date;
    editedBy: string;
}

export interface IStore {
    _id: string;
    id: string;
    owner: IUser;
    title: string;
    subtitle: string;
    store_type: string;
    info: string;
    brand_url: string;
    createdBy: string;
    cat_main: string;
    sub_cat: string;
    address: string;
    city: string;
    country: string;
    region: string;
    zip: string;
    logo_img: string;
    banner_img: string;
    background_img: string;
    tx_payment: string;
    is_active: boolean;
    is_payed: boolean;
    membership_length: number;
    purchasedOn: Date;
    expiresOn: Date;
    store_ads: IPost[];
    createdAt: Date;
    updatedAt: Date;
    logo_text_color?: string;
    store_header_color?: string;
    store_subtitle_color?: string;
    previousEdits: StoreEditRecord[];
    isHidden: boolean;
}
