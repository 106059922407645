import { IEmailTemplate } from "../../types/EmailTemplate";

export enum EmailActionTypes {
    FETCH_EMAILTEMPLATE = '@@emailTemplate/FETCH_EMAILTEMPLATE',
    UPDATE_EMAILTEMPLATE = '@@emailTemplate/UPDATE_EMAILTEMPLATE',
    EMAILTEMPLATE_UPDATED = '@@emailTemplate/EMAILTEMPLATE_UPDATED',
    FETCH_EMAILTEMPLATES = '@@emailTemplate/FETCH_EMAILTEMPLATES',
    FETCHED_EMAILTEMPLATES = '@@admin/FETCHED_EMAILTEMPLATES',
    REMOVE_EMAILTEMPLATE = '@@admin/REMOVE_EMAILTEMPLATE',
    ADD_EMAILTEMPLATE = '@@admin/ADD_EMAILTEMPLATE',
    FETCH_SUCCESS = '@@emailTemplate/FETCH_SUCCESS',
    FETCH_ERROR = '@@emailTemplate/FETCH_ERROR',
    FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS"
}

export interface EmailState {
    readonly loading: boolean;
    readonly emailTemplates: IEmailTemplate[]
    readonly emailTemplate: IEmailTemplate
    readonly errors?: string
}