import { IUser } from './User';
import { ICategory } from './Category';
import { Transaction } from './Transaction';
import { Question } from './Question';

export interface IPostFilterKind {
    typeId: string;
    kindId: string;
}

export interface PostEditRecord {
    post: IPost;
    editDate: Date;
    editedBy: string;
}

export interface BeeditWayValue {
    type: 'fixed' | 'percent';
    amount: number;
}

export interface IPost {
    _id: string;
    id: string;
    title: string;
    category1: ICategory;
    category2: ICategory;
    category3: ICategory;
    cat_main: string;
    sub_cat: string;
    cat_main_2?: string;
    sub_cat_2?: string;
    sub_cat_section_2?: string;
    url_slug: string;
    sub_cat_section: string;
    ad_status: string;
    disadvantage: string;
    short_description: string;
    long_description: string;
    bulk_count: number;
    is_bulk: boolean;
    delivery_terms: string;
    sale_location: string;
    sale_location_countries: string[];
    delivery_option: string;
    delivery_fee: string;
    delivery_location: string;
    delivery_countries: string[];
    // can_set_price: boolean;
    min_price: number;
    bulk_group_id: string;
    max_price: number;
    ad_images: string[];
    deadline?: Date;
    beed_time?: IPostTime;
    period_time: IPostTime;
    frame_color: string;
    ad_effect: string;
    quick_sale: string;
    // sale_minutes: number
    // sale_seconds: number
    publishedAt: Date;
    // store: string;
    tpEnabled: boolean;
    is_freeBee: boolean;
    is_upperTimer: boolean;
    is_published: boolean;
    is_payed: boolean;
    is_started: boolean;
    startedAt: Date;
    last_bid: IBid;
    bids: IBid[];
    store: string;
    bidders: IUser[];
    createdBy: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    filterKinds: IPostFilterKind[];
    quick_timer?: IPostTime;
    weenitStartAt?: Date;
    previousEdits: PostEditRecord[];
    transactions: Transaction[];
    owner: IUser;
    extra_details: string;
    contact_info?: string;
    is_external: boolean;
    is_surveyDemo?: boolean;
    demoLink?: string;
    is_demo: boolean;
    currency: string;
    isHidden: boolean;
    questions: Question[];
    askers: IUser[];

    isSurveyExcluded: boolean;
    beedit_way: boolean;
    beedit_way_value?: BeeditWayValue;
}

export enum PostHistoryActionType {
    CREATED = 'created',
    PUBLISHED = 'published',
    POST_EDITED = 'post_edited',
    PAID_BULK = 'paid_bulk',
    PAID_ADDPOST = 'paid_addpost',
    SELLER_DEPOSIT = 'seller_deposited',
    DELETED = 'deleted',
    EXPIRED = 'expired',
    NEW_BID = 'new_bid',
    NEW_TP_BID = 'new_tp_bid',
    BID_WON = 'bid_won',
    ORDER_PAYED = 'order_payed',
    ORDER_REFUNDED = 'order_refunded',
    DEPOSIT_REFUNDED = 'deposit_refunded',
    ADMIN_REFUND = 'admin_refund',
}

export interface PostHistoryAction {
    id: string;
    action: PostHistoryActionType;
    date: Date;
    source: JSX.Element | string;
    description: JSX.Element | string;
    related?: {
        tx?: Transaction;
        bid?: IBid;
        users?: IUser[];
        post?: IPost;
    };
}

export interface IPostTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export interface IPostFee {
    post: number;
    period: number;
    frame: number;
    effect: number;
    total: number;
    taxes: number;
    netTotal: number;
    deposit: number;
    toPayTotal: number;
}

export interface IBid {
    id: string;
    _id: string;
    count: number;
    post_id: string;
    bulk_id?: string;
    post: IPost[];
    bidder_id: string;
    fee_tx: string;
    final_tx: string[];
    bidder: IUser | undefined;
    amount: number;
    status: string;
    createdAt: Date;
    is_tp: boolean;
    tp_min: number;
}
