import { ThemeColors } from './store/layout';
import { Store } from 'redux';
import { ApplicationState } from './store';
import * as React from 'react';
import { Provider, connect } from 'react-redux';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast, ToastId } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Any additional component props go here.
interface MainProps {
    store: Store<ApplicationState>;
}

interface IState {
    isDisconnected: boolean;
    offlineToastId?: ToastId;
}

class Main extends React.Component<MainProps, IState> {
    public constructor(props: MainProps) {
        super(props);
        this.state = {
            isDisconnected: false,
        };
    }

    public onSWUpdate = (registration: ServiceWorkerRegistration) => {
        // setShowReload(true);
        // setWaitingWorker(registration.waiting);
        // console.log('A new version is available!', registration.waiting);
        toast.info('A new version is available! Click to update', {
            position: 'bottom-center',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            onClose: () => {
                if (registration.waiting) registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                // console.log('skipping', registration.waiting);
                // setShowReload(false);
                window.location.reload(true);
            },
        });
    };

    public componentDidMount() {
        serviceWorker.register({ onUpdate: this.onSWUpdate });
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }
    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }
    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            if (this.state.isDisconnected) {
                console.log('offline', this.state.offlineToastId);
                if (this.state.offlineToastId) toast.dismiss(this.state.offlineToastId);
                toast.success('Online connection restored. All functionality enabled', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            }

            this.setState({ isDisconnected: false, offlineToastId: undefined });
            return;
        }
        let offlineToastId = this.state.offlineToastId;
        if (!offlineToastId) {
            offlineToastId = toast.info('You are offline. Some functionality is disabled', {
                position: 'bottom-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
        return this.setState({ isDisconnected: true, offlineToastId });
    };
    public render() {
        const { store } = this.props;
        return (
            <Provider store={store}>
                <div>
                    <ToastContainer />
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </div>
            </Provider>
        );
    }
}

export default Main;
