import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { StoreTypeActionTypes } from './types';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
// import { IStoreType } from '../../types/storetype';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import {
    fetchStoreType,
    fetchError,
    fetchSuccess,
    onFetchedStoreTypes,
    updateStoreType,
    fetchStoreTypes,
    removeStoreType,
    addStoreType,
    onFetchedStores,
    updateStore,
    fetchStores,
} from './actions';
import { IStoreType, IStore } from '../../types/Store';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;

function* handleFetchStoreType(action: ReturnType<typeof fetchStoreType>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/storetype/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let storeTypeRes: IStoreType = yield call(callApi, 'get', API_ENDPOINT, `/storetype/${action.payload}`, token);
        // console.log("fetched storetype template", storeTypeRes)

        yield put(fetchSuccess(storeTypeRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchStores() {
    try {
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let res: IStore[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/store/admin', token);

        // console.log("fetched stores", res)

        if (res.error) {
            console.log(res);
            yield put(fetchError('Error fetching stores'));
            toast('Error fetching stores', { type: toast.TYPE.ERROR });
        } else {
            // toast("storetype template data updated", { type: toast.TYPE.SUCCESS });
            console.log('fetched', res);
            yield put(onFetchedStores(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchStoreTypes() {
    try {
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let res: IStoreType[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/storetype/', token);

        // console.log("fetched storetype templates", res)

        if (res.error) {
            console.log(res);
            yield put(fetchError('Error fetching storetypes'));
            toast('Error fetching storetypes', { type: toast.TYPE.ERROR });
        } else {
            // toast("storetype template data updated", { type: toast.TYPE.SUCCESS });
            console.log('fetched', res);
            yield put(onFetchedStoreTypes(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateStoreType(action: ReturnType<typeof updateStoreType>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/storetype/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating storetype template'));
            toast('Error updating storetype template', { type: toast.TYPE.ERROR });
        } else {
            toast('storetype template data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchStoreTypes());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveStoreType(action: ReturnType<typeof removeStoreType>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/storetype/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing storetype'));
            toast('Error removing storetype', { type: toast.TYPE.ERROR });
        } else {
            toast('storetype removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchStoreTypes());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddStoreType(action: ReturnType<typeof addStoreType>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/storetype/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchStoreTypes());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateStore(action: ReturnType<typeof updateStore>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/store/admin/${action.payload._id}`,
            token,
            action.payload,
        );

        // console.log(res)
        if (res.error) {
            // console.log(res)
            yield put(fetchError('Error updating post'));
            toast('Error updating store', { type: toast.TYPE.ERROR });
        } else {
            toast('Store data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchStores());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchRequest() { yield takeEvery(StoreTypeActionTypes.FETCH_storetype, handleFetchstoretype) }
function* watchUpdateStoreType() {
    yield takeEvery(StoreTypeActionTypes.UPDATE_STORETYPE, handleUpdateStoreType);
}
function* watchFetchStores() {
    yield takeEvery(StoreTypeActionTypes.FETCH_STORES, handleFetchStores);
}
function* watchFetchStoreType() {
    yield takeEvery(StoreTypeActionTypes.FETCH_STORETYPE, handleFetchStoreType);
}
function* watchFetchStoreTypes() {
    yield takeEvery(StoreTypeActionTypes.FETCH_STORETYPES, handleFetchStoreTypes);
}
function* watchAddStoreType() {
    yield takeEvery(StoreTypeActionTypes.ADD_STORETYPE, handleAddStoreType);
}
function* watchRemoveStoreType() {
    yield takeEvery(StoreTypeActionTypes.REMOVE_STORETYPE, handleRemoveStoreType);
}

function* watchUpdateStore() {
    yield takeEvery(StoreTypeActionTypes.EDIT_STORE, handleUpdateStore);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* storeTypeSaga() {
    yield all([
        fork(watchUpdateStoreType),
        fork(watchFetchStoreType),
        fork(watchFetchStoreTypes),
        fork(watchAddStoreType),
        fork(watchRemoveStoreType),
        fork(watchFetchStores),
        fork(watchUpdateStore),
    ]);
}
