import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { PostActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updatePost,
    fetchPosts,
    removePost,
    addPost,
    onFetchedPosts,
    onFetchedTransactions,
} from './actions';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { IPost } from '../../types/Post';
import { Transaction } from '../../types/Transaction';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getUser = (state: ApplicationState) => state.user.user;

function* handlefetchPosts() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/post/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        // console.log(token)
        if (!token) return yield put(fetchError('Token not in state.'));

        let postRes: IPost[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/post/admin/', token);
        // // console.log("fetched posts", postRes)

        if (postRes.error) {
            // console.log(postRes);
            yield put(fetchError('Error fetching posts'));
        } else {
            yield put(onFetchedPosts(postRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        // console.log(err)
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handlefetchTransactions() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/post/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        // console.log(token)
        if (!token) return yield put(fetchError('Token not in state.'));

        let txRes: Transaction[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/post/admin/tx', token);
        // // console.log("fetched posts", postRes)

        if (txRes.error) {
            // console.log(postRes);
            yield put(fetchError('Error fetching transactions'));
        } else {
            yield put(onFetchedTransactions(txRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        // console.log(err)
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleupdatePost(action: ReturnType<typeof updatePost>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/post/admin/${action.payload._id}`,
            token,
            action.payload,
        );

        // console.log(res)
        if (res.error) {
            // console.log(res)
            yield put(fetchError('Error updating post'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('Post data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchPosts());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleremovePost(action: ReturnType<typeof removePost>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/post/admin/${action.payload}`,
            token,
        );

        // console.log(res)
        if (res.error) {
            // console.log(res)
            yield put(fetchError('Error removing post'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('Post removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchPosts());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        // console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleaddPost(action: ReturnType<typeof addPost>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/post/admin/demo/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchPosts());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// function* handleUploadAvatar(action: ReturnType<typeof uploadAvatar>) {
//     try {
//         // To call async functions, use redux-saga's `call()`.
//         const token: string = yield select(getToken);
//         if (!token) return yield put(fetchError('Token not in state.'))
//         // console.log(token);

//         // const formData = new FormData()
//         //     formData.append(
//         //         'newAvatar',
//         //         this.state.selectedFile!,
//         //         this.state.selectedFile!.name
//         //     );
//         const data = new FormData();
//         data.append('file', action.payload);
//         // data.append('filename', this.fileName.value);

//         const res: ApiResponse & ApiError = yield call(callApiUpload, 'POST', API_ENDPOINT, `/post/uploadavatar`, token, data)

//         // console.log(res)
//         if (res.error) {
//             // console.log(res)
//             yield put(fetchError('Error updating avatar'))
//             toast("Error updating avatar", { type: toast.TYPE.ERROR });
//         } else {
//             toast("Avatar updated", { type: toast.TYPE.SUCCESS });
//             yield put(fetchPosts())

//             // yield put(newGameTime(res))
//         }
//     } catch (err) {
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.

function* watchfetchTransactions() {
    yield takeEvery(PostActionTypes.FETCH_TRANSACTIONS, handlefetchTransactions);
}
function* watchupdatePost() {
    yield takeEvery(PostActionTypes.UPDATE_POST, handleupdatePost);
}
// function* watchUploadAvatar() { yield takeEvery(PostActionTypes.UPLOAD_AVATAR, handleUploadAvatar) }
function* watchfetchPosts() {
    yield takeEvery(PostActionTypes.FETCH_POSTS, handlefetchPosts);
}
function* watchaddPost() {
    yield takeEvery(PostActionTypes.ADD_POST, handleaddPost);
}
function* watchremovePost() {
    yield takeEvery(PostActionTypes.REMOVE_POST, handleremovePost);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* postSaga() {
    yield all([
        fork(watchfetchTransactions),
        fork(watchupdatePost),
        // fork(watchUploadAvatar),
        fork(watchfetchPosts),
        fork(watchaddPost),
        fork(watchremovePost),
    ]);
}
