import { Reducer } from 'redux';
import { StoreTypeState, StoreTypeActionTypes } from './types';
import { IStoreType } from '../../types/Store';

// Type-safe initialState!
const initialState: StoreTypeState = {
    storeType: {} as IStoreType,
    storeTypes: [] as IStoreType[],
    errors: undefined,
    loading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<StoreTypeState> = (state = initialState, action) => {
    switch (action.type) {
        case StoreTypeActionTypes.FETCH_STORETYPES ||
            StoreTypeActionTypes.UPDATE_STORETYPE ||
            StoreTypeActionTypes.FETCH_STORES ||
            StoreTypeActionTypes.EDIT_STORE ||
            StoreTypeActionTypes.ADD_STORETYPE: {
            return { ...state, loading: true };
        }
        case StoreTypeActionTypes.FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                storeType: action.payload.user,
            };
        }
        case StoreTypeActionTypes.FETCHED_STORES: {
            // console.log('sd', action.payload);
            return { ...state, loading: false, stores: action.payload };
        }
        case StoreTypeActionTypes.FETCHED_STORETYPES: {
            // console.log('sd', action.payload);
            return { ...state, loading: false, storeTypes: action.payload };
        }
        case StoreTypeActionTypes.STORETYPE_UPDATED: {
            return { ...state, loading: false, storeType: action.payload };
        }
        case StoreTypeActionTypes.FETCH_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as storeTypeReducer };
