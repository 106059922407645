import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { PageActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updatePageTemplate,
    fetchPageTemplates,
    removePageTemplate,
    addPageTemplate,
    onFetchedPageTemplates,
    fetchPageTemplate,
} from './actions';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
// import { IPageTemplate } from '../../types/page';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { IPageTemplate } from '../../types/PageTemplate';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;

function* handleFetchPageTemplate(action: ReturnType<typeof fetchPageTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/page/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let pageTemplateRes: IPageTemplate = yield call(callApi, 'get', API_ENDPOINT, `/page/${action.payload}`, token);
        // console.log("fetched page template", pageTemplateRes)

        yield put(fetchSuccess(pageTemplateRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchPageTemplates() {
    try {
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let pageTemplatesRes: IPageTemplate[] = yield call(callApi, 'get', API_ENDPOINT, '/page/', token);

        // console.log("fetched page templates", pageTemplatesRes)

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(onFetchedPageTemplates(pageTemplatesRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdatePageTemplate(action: ReturnType<typeof updatePageTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/page/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating page template'));
            toast('Error updating page template', { type: toast.TYPE.ERROR });
        } else {
            toast('Page template data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchPageTemplates());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemovePageTemplate(action: ReturnType<typeof removePageTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/page/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing page'));
            toast('Error removing page', { type: toast.TYPE.ERROR });
        } else {
            toast('Page removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchPageTemplates());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddPageTemplate(action: ReturnType<typeof addPageTemplate>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/page/`, token, action.payload);

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchPageTemplates());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchRequest() { yield takeEvery(PageActionTypes.FETCH_page, handleFetchpage) }
function* watchUpdatePageTemplate() {
    yield takeEvery(PageActionTypes.UPDATE_PAGETEMPLATE, handleUpdatePageTemplate);
}
function* watchFetchPageTemplate() {
    yield takeEvery(PageActionTypes.FETCH_PAGETEMPLATE, handleFetchPageTemplate);
}
function* watchFetchPageTemplates() {
    yield takeEvery(PageActionTypes.FETCH_PAGETEMPLATES, handleFetchPageTemplates);
}
function* watchAddPageTemplate() {
    yield takeEvery(PageActionTypes.ADD_PAGETEMPLATE, handleAddPageTemplate);
}
function* watchRemovePageTemplate() {
    yield takeEvery(PageActionTypes.REMOVE_PAGETEMPLATE, handleRemovePageTemplate);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* pageSaga() {
    yield all([
        fork(watchUpdatePageTemplate),
        fork(watchFetchPageTemplate),
        fork(watchFetchPageTemplates),
        fork(watchAddPageTemplate),
        fork(watchRemovePageTemplate),
    ]);
}
