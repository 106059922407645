import { Reducer } from 'redux'
import { EmailState, EmailActionTypes } from './types'
import { IEmailTemplate } from '../../types/EmailTemplate';


// Type-safe initialState!
const initialState: EmailState = {
    emailTemplate: {} as IEmailTemplate,
    emailTemplates: [] as IEmailTemplate[],
    errors: undefined,
    loading: false,
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<EmailState> = (state = initialState, action) => {
    switch (action.type) {
        case EmailActionTypes.FETCH_EMAILTEMPLATES ||
        EmailActionTypes.UPDATE_EMAILTEMPLATE || 
        EmailActionTypes.ADD_EMAILTEMPLATE : {
            return { ...state, loading: true }
        }
        case EmailActionTypes.FETCH_SUCCESS: {
            return {
                ...state, loading: false,
                category: action.payload.user,
            }
        }
        case EmailActionTypes.FETCHED_EMAILTEMPLATES: {
            return { ...state, loading: false, emailTemplates: action.payload }
        }
        case EmailActionTypes.EMAILTEMPLATE_UPDATED: {
            return { ...state, loading: false, emailTemplate: action.payload }
        }
        case EmailActionTypes.FETCH_ERROR: {
            return { ...state, loading: false, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as emailTemplateReducer }