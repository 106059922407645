import * as React from 'react';

function Page404() {
    return (
        <div className="404">
            <h2>Welcome to beediT Admin Control Panel. Choose menu from top buttons</h2>
        </div>
    );
}

export default Page404;
