import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// import logo from './logo.svg';
// import Login from './pages/';
// import Home from './components/Home';
// import Page404 from './components/Page404';
import Root from './components/layout/Root';
import Login from './pages/Login';
import Page404 from './pages/Page404';
// import Register from './pages/Register';
// import Home from './pages/Home';
// import RootInner from './components/layout/RootInner';
// import Profile from './pages/Profile';
// import Dashboard from './pages/Dashboard';
// import Main from './components/Main';
import Admin from './components/Admin';

// import Footer from './components/bodyParts/Footer';
// import Header from './components/bodyParts/Header';
// import { I18n } from 'react-i18next';
// import * as i18next from 'i18next';
// import i18n from './i18n';
// import { I18n } from 'react-i18next';

export interface IProps {

}

export interface IState {
    // wow: any,
}

class Routes extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            // wow: new WOW({ mobile: false })
        };
        // this.state.wow.init();

    }

    onLoad() {
        // this.state.wow.sync();
    }

    public componentDidMount() {

    }

    public render() {
        return (
            <Root>
                <Switch>
                    <Route path="/admin" component={Admin} />

                    <Route exact path="/" component={Login} />
                    <Route component={Page404} status={404} />
                </Switch>
            </Root>
        );
    }

}

export default Routes;
