import { action } from 'typesafe-actions'
import { EmailActionTypes } from './types'
import { IEmailTemplate } from '../../types/EmailTemplate';

export const fetchSuccess = (
    emailTemplate: IEmailTemplate,
) => action(EmailActionTypes.FETCH_SUCCESS, {
    emailTemplate
})
export const fetchEmailTemplate = (type: string) => action(EmailActionTypes.FETCH_EMAILTEMPLATE, type)
export const emailTemplateUpdated = (emailTemplate: IEmailTemplate) => action(EmailActionTypes.EMAILTEMPLATE_UPDATED, emailTemplate)

export const onFetchedEmailTemplates = (emailTemplates: IEmailTemplate[]) => action(EmailActionTypes.FETCHED_EMAILTEMPLATES, emailTemplates)
export const fetchEmailTemplates = () => action(EmailActionTypes.FETCH_EMAILTEMPLATES)
export const updateEmailTemplate = (emailTemplate: IEmailTemplate) => action(EmailActionTypes.UPDATE_EMAILTEMPLATE, emailTemplate)
export const addEmailTemplate = (emailTemplate: IEmailTemplate) => action(EmailActionTypes.ADD_EMAILTEMPLATE, emailTemplate)
export const removeEmailTemplate = (emailId: string) => action(EmailActionTypes.REMOVE_EMAILTEMPLATE, emailId)


export const fetchAllSuccess = () => action(EmailActionTypes.FETCH_ALLSUCCESS)
export const fetchError = (message: string) => action(EmailActionTypes.FETCH_ERROR, message)