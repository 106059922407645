import { Survey } from "../../types/Survey";

export enum SurveyActionTypes {
    FETCHALL = '@@survey/FETCHALL',
    FETCHEDALL = '@@survey/FETCHEDALL',

    UPDATE = '@@survey/UPDATE',
    REMOVE = '@@survey/REMOVE',
    ADD = '@@survey/ADD',

    SUCCESS = '@@survey/SUCCESS',
    ERROR = '@@survey/ERROR',
    // FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS"
}

export interface SurveyState {
    readonly loading: boolean;
    readonly surveys: Survey[]
    readonly errors?: string
}