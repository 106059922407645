import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { EmailActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updateEmailTemplate,
    fetchEmailTemplates,
    removeEmailTemplate,
    addEmailTemplate,
    onFetchedEmailTemplates,
    fetchEmailTemplate,
} from './actions';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
// import { IEmailTemplate } from '../../types/email';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { IEmailTemplate } from '../../types/EmailTemplate';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;

function* handleFetchEmailTemplate(action: ReturnType<typeof fetchEmailTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/email/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let emailTemplateRes: IEmailTemplate = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/email/${action.payload}`,
            token,
        );
        // console.log("fetched email template", emailTemplateRes)

        yield put(fetchSuccess(emailTemplateRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchEmailTemplates() {
    try {
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        let emailTemplatesRes: IEmailTemplate[] = yield call(callApi, 'get', API_ENDPOINT, '/email/', token);

        // console.log("fetched email templates", emailTemplatesRes)

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(onFetchedEmailTemplates(emailTemplatesRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateEmailTemplate(action: ReturnType<typeof updateEmailTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/email/${action.payload.type}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating email template'));
            toast('Error updating email template', { type: toast.TYPE.ERROR });
        } else {
            toast('Email template data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchEmailTemplates());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveEmailTemplate(action: ReturnType<typeof removeEmailTemplate>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/email/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing email'));
            toast('Error removing email', { type: toast.TYPE.ERROR });
        } else {
            toast('Email removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchEmailTemplates());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddEmailTemplate(action: ReturnType<typeof addEmailTemplate>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/email/`, token, action.payload);

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchEmailTemplates());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchRequest() { yield takeEvery(EmailActionTypes.FETCH_email, handleFetchemail) }
function* watchUpdateEmailTemplate() {
    yield takeEvery(EmailActionTypes.UPDATE_EMAILTEMPLATE, handleUpdateEmailTemplate);
}
function* watchFetchEmailTemplate() {
    yield takeEvery(EmailActionTypes.FETCH_EMAILTEMPLATE, handleFetchEmailTemplate);
}
function* watchFetchEmailTemplates() {
    yield takeEvery(EmailActionTypes.FETCH_EMAILTEMPLATES, handleFetchEmailTemplates);
}
function* watchAddEmailTemplate() {
    yield takeEvery(EmailActionTypes.ADD_EMAILTEMPLATE, handleAddEmailTemplate);
}
function* watchRemoveEmailTemplate() {
    yield takeEvery(EmailActionTypes.REMOVE_EMAILTEMPLATE, handleRemoveEmailTemplate);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* emailSaga() {
    yield all([
        fork(watchUpdateEmailTemplate),
        fork(watchFetchEmailTemplate),
        fork(watchFetchEmailTemplates),
        fork(watchAddEmailTemplate),
        fork(watchRemoveEmailTemplate),
    ]);
}
