import { action } from 'typesafe-actions';
import { SettingActionTypes } from './types';
import { IUser } from '../../types/User';
import { ICategory } from '../../types/Category';
import { ISetting, IEffect, BackupCheckResponse, Translation, RegisterPromoCode } from '../../types/Setting';
import { LockKey } from '../../types/LockKey';
import { TitleSentence } from '../../types/TitleSentence';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const fetchSettings = () => action(SettingActionTypes.FETCH_SETTINGS);
export const fetchedSettings = (settings: ISetting[]) => action(SettingActionTypes.FETCHED_SETTINGS, settings);
export const updateSetting = (setting: ISetting) => action(SettingActionTypes.UPDATE_SETTING, setting);

export const uploadImage = (image: File, folder: string, fileName: string) =>
    action(SettingActionTypes.UPLOAD_IMAGE, { image, folder, fileName });
export const restoreDb = (types: string[], file: File, from: Date, to: Date) =>
    action(SettingActionTypes.DB_RESTORE, { types, file, from, to });
export const backupCheck = (file: File) => action(SettingActionTypes.DB_BACKUP_CHECK, file);
export const backupChecked = (result: BackupCheckResponse) => action(SettingActionTypes.DB_BACKUP_CHECKED, result);
export const backupDb = (types: string[], from: Date, to: Date) =>
    action(SettingActionTypes.DB_BACKUP, { types, from, to });

export const categoriesImportCSV = (file: File) => action(SettingActionTypes.CATEGORIES_IMPORT_CSV, file);

export const fetchEffects = () => action(SettingActionTypes.FETCH_EFFECTS);
export const fetchedEffects = (effects: IEffect[]) => action(SettingActionTypes.FETCHED_EFFECTS, effects);
export const updateEffect = (effect: IEffect) => action(SettingActionTypes.UPDATE_EFFECT, effect);
export const addEffect = (effect: IEffect) => action(SettingActionTypes.ADD_EFFECT, effect);
export const removeEffect = (effectId: string) => action(SettingActionTypes.REMOVE_EFFECT, effectId);

export const fetchLockKeys = () => action(SettingActionTypes.FETCH_LOCKKEYS);
export const fetchedLockKeys = (lockKeys: LockKey[]) => action(SettingActionTypes.FETCHED_LOCKKEYS, lockKeys);
export const updateLockKey = (lockKey: LockKey) => action(SettingActionTypes.UPDATE_LOCKKEY, lockKey);
export const addLockKey = (lockKey: LockKey) => action(SettingActionTypes.ADD_LOCKKEY, lockKey);
export const removeLockKey = (id: string) => action(SettingActionTypes.REMOVE_LOCKKEY, id);

export const fetchTranslation = (lang: 'en' | 'he') => action(SettingActionTypes.FETCH_TRANSLATION, lang);
export const fetchedTranslation = (lang: 'en' | 'he', translation: Translation) =>
    action(SettingActionTypes.FETCHED_TRANSLATION, { lang, translation });
export const updateTranslation = (lang: 'en' | 'he', translation: Translation) =>
    action(SettingActionTypes.UPDATE_TRANSLATION, { lang, translation });

export const fetchPromos = () => action(SettingActionTypes.FETCH_PROMOS);
export const fetchedPromos = (promos: RegisterPromoCode[]) => action(SettingActionTypes.FETCHED_PROMOS, promos);
export const updatePromo = (promo: RegisterPromoCode) => action(SettingActionTypes.UPDATE_PROMO, promo);
export const addPromo = (promo: RegisterPromoCode) => action(SettingActionTypes.ADD_PROMO, promo);
export const removePromo = (promoId: string) => action(SettingActionTypes.REMOVE_PROMO, promoId);

export const fetchSentences = () => action(SettingActionTypes.FETCH_SENTENCES);
export const fetchedSentences = (sentences: TitleSentence[]) => action(SettingActionTypes.FETCHED_SENTENCES, sentences);
export const updateSentence = (sentence: TitleSentence) => action(SettingActionTypes.UPDATE_SENTENCE, sentence);
export const addSentence = (sentence: TitleSentence) => action(SettingActionTypes.ADD_SENTENCE, sentence);
export const removeSentence = (sentenceId: string) => action(SettingActionTypes.REMOVE_SENTENCE, sentenceId);

export const fetchError = (message: string) => action(SettingActionTypes.FETCH_ERROR, message);
