import * as React from "react";
import { tokenRequest, authCompleted, tokenReceived, tokenSocialRequest } from "../store/login/actions";
import { RouteComponentProps, Redirect } from "react-router";
import { ConnectedReduxProps, ApplicationState } from "../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAuth } from "../types/Auth";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import * as jwt from 'jsonwebtoken';

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState {
    loggedIn: boolean;
    loginToken: string;
    errors?: string;
    isRegistered: boolean;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    tokenRequest: typeof tokenRequest;
    tokenReceived: typeof tokenReceived;
    tokenSocialRequest: typeof tokenSocialRequest;
    // registerNew: typeof registerNew
    // authCompleted: typeof authCompleted
}

export interface IProps {
    name: string;
}

interface IState {
    login: string;
    password: string;
    saveLogin: boolean;
    validated: boolean;
}

type AllProps = IProps & RouteComponentProps<{}> & ConnectedReduxProps & PropsFromState & PropsFromDispatch

class Login extends React.Component<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            login: '',
            password: '',
            saveLogin: true,
            validated: false
        };
    }
    public componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            var decodedToken = jwt.decode(token, { complete: true });
            this.props.tokenReceived(token);
        }
    }

    handleSubmit(event: any) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        event.stopPropagation();
        this.props.tokenRequest({ username: this.state.login, password: this.state.password } as IAuth);
        this.setState({ validated: true });
    }
    public render() {
        const { validated } = this.state;
        if (this.props.loggedIn) return (<Redirect to="/admin/" />)
        return <div className="login-box">
            <div className="login-logo">
                <Link to={`/`}>
                    <img src="images/logo.png" alt="" />
                </Link>
            </div>
            <div className="tab-wrap">
                <Form
                    noValidate
                    validated={validated}
                    className="myform"
                    onSubmit={(e: any) => this.handleSubmit(e)}
                >

                    <Form.Group controlId="formUsername">
                        <Row>
                            <Col><Form.Label>Email or user name</Form.Label></Col>
                            <Col>
                                <Form.Control type="text" name="emailid" value={this.state.login} onChange={(el: any) => this.setState({ login: el.target.value })} />

                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group controlId="formPass">
                        <Row>
                            <Col><Form.Label>Password *</Form.Label></Col>
                            <Col>
                                <Form.Control type="password" name="password" value={this.state.password} onChange={(el: any) => this.setState({ password: el.target.value })} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Button variant="secondary" type="submit" className="btn btn-main btn-block">Login Admin</Button>
                </Form>
            </div>
        </div>
    }

}

const mapStateToProps = ({ login }: ApplicationState) => ({
    loggedIn: login.loggedIn,
    errors: login.errors,
    loginToken: login.token,
    isRegistered: login.registerCompleted
})

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => ({
    tokenRequest: (auth: IAuth) => dispatch(tokenRequest(auth)),
    tokenReceived: (token: string) => dispatch(tokenReceived(token)),
    tokenSocialRequest: (type: string, social_token: string) => dispatch(tokenSocialRequest(type, social_token))
    // tokenLogin: (loginReq: LoginRequest) => dispatch(tokenLogin(loginReq)),
    // registerNew: (newUser: IUser) => dispatch(registerNew(newUser)),
    // authCompleted: (token: string) => dispatch(authCompleted(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)