import { Reducer } from 'redux'
import { LoginActionTypes, LoginState } from './types'

// Type-safe initialState!
const initialState: LoginState = {
    token: '',
    loggedIn: false,
    errors: undefined,
    loading: false,
    registerCompleted: false
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LoginState> = (state = initialState, action) => {
    switch (action.type) {
        case LoginActionTypes.TOKEN_REQUEST: {
            return { ...state, loading: false }
        }
        case LoginActionTypes.TOKEN_LOGIN: {
            return { ...state, loading: false }
        }
        case LoginActionTypes.LOGOUT: {
            return { ...state, loading: false, loggedIn: false }
        }
        case LoginActionTypes.LOGOUT_COMPLETED: {
            console.log(action.type, action.payload);
            return { ...state, loading: false, token: '', loggedIn: false };
        }
        case LoginActionTypes.TOKEN_RECEIVED: {
            // console.log(action.type, action.payload)
            localStorage.setItem('token', action.payload);
            return { ...state, loading: true, token: action.payload, loggedIn: true }
        }
        case LoginActionTypes.AUTH_COMPLETED: {
            // console.log(action.type, action.payload)
            return { ...state, loading: true, authSignature: action.payload }
        }
        case LoginActionTypes.REGISTER_NEW: {
            return { ...state, loading: true }
        }
        case LoginActionTypes.REGISTER_COMPLETED: {
            // console.log(action.type, action.payload)
            return { ...state, loading: true, registerCompleted: true }
        }
        case LoginActionTypes.FETCH_ERROR: {
            return { ...state, loading: true, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as loginReducer }