import { IPost } from "../../types/Post";
import { Transaction } from "../../types/Transaction";

export enum PostActionTypes {
    FETCH_POST = '@@post/FETCH_POST',
    UPDATE_POST = '@@post/UPDATE_POST',
    POST_UPDATED = '@@post/POST_UPDATED',
    UPLOAD_AVATAR = '@@post/UPLOAD_AVATAR',
    FETCH_POSTS = '@@post/FETCH_POSTS',
    FETCHED_POSTS = '@@admin/FETCHED_POSTS',
    REMOVE_POST = '@@admin/REMOVE_POST',
    ADD_POST = '@@admin/ADD_POST',
    FETCH_SUCCESS = '@@post/FETCH_SUCCESS',
    FETCH_ERROR = '@@post/FETCH_ERROR',
    FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS",

    FETCH_TRANSACTIONS = '@@tx/FETCH_TRANSACTIONS',
    FETCHED_TRANSACTIONS = '@@tx/FETCHED_TRANSACTIONS',
}

export interface PostState {
    readonly loading: boolean;
    readonly posts: IPost[];
    readonly transactions?: Transaction[];
    readonly post: IPost;
    readonly errors?: string;
}