import { Reducer } from 'redux'
import { PageState, PageActionTypes } from './types'
import { IPageTemplate } from '../../types/PageTemplate';


// Type-safe initialState!
const initialState: PageState = {
    pageTemplate: {} as IPageTemplate,
    pageTemplates: [] as IPageTemplate[],
    errors: undefined,
    loading: false,
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<PageState> = (state = initialState, action) => {
    switch (action.type) {
        case PageActionTypes.FETCH_PAGETEMPLATES ||
        PageActionTypes.UPDATE_PAGETEMPLATE || 
        PageActionTypes.ADD_PAGETEMPLATE : {
            return { ...state, loading: true }
        }
        case PageActionTypes.FETCH_SUCCESS: {
            return {
                ...state, loading: false,
                category: action.payload.user,
            }
        }
        case PageActionTypes.FETCHED_PAGETEMPLATES: {
            return { ...state, loading: false, pageTemplates: action.payload }
        }
        case PageActionTypes.PAGETEMPLATE_UPDATED: {
            return { ...state, loading: false, pageTemplate: action.payload }
        }
        case PageActionTypes.FETCH_ERROR: {
            return { ...state, loading: false, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as pageTemplateReducer }