import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LoginActionTypes } from './types';
import {
    fetchError,
    tokenRequest,
    authCompleted,
    tokenReceived,
    registerNew,
    registerCompleted,
    logout,
    logoutCompleted,
    registerSocial,
    tokenSocialRequest,
} from './actions';
// import { handleListenUsers } from '../user/sagas';
import callApi from '../../utils/api';
import { ApiError } from '../../types/ApiResponse';
import { IAuth } from '../../types/Auth';
import { IUser } from '../../types/User';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { toast } from 'react-toastify';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleTokenRequest(action: ReturnType<typeof tokenRequest>) {
    try {
        const token = yield call(callApi, 'post', API_ENDPOINT, `/auth/loginadmin`, '', action.payload);

        if (token.error) {
            toast(token.error);
            yield put(fetchError(token.error));
        } else {
            yield put(tokenReceived(token.jwt));
            // yield put(handleListenUsers());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSocialTokenRequest(action: ReturnType<typeof tokenSocialRequest>) {
    try {
        const token = yield call(callApi, 'post', API_ENDPOINT, `/auth/social-login`, '', action.payload);

        // console.log(token);
        if (token.error) {
            yield put(fetchError(token.error));
        } else {
            yield put(tokenReceived(token.jwt));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleLogout() {
    try {
        console.log('logout!');
        localStorage.removeItem('token');
        yield put(logoutCompleted());
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        }
    }
}

function* handleRegisterNew(action: ReturnType<typeof registerNew>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/register`, '', action.payload);

        if (res.error) {
            yield put(fetchError(res.error));
            console.log(res.error);
        } else {
            yield put(tokenRequest({ username: action.payload.username, password: action.payload.password } as IAuth));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRegisterSocial(action: ReturnType<typeof registerSocial>) {
    try {
        let newUser = {} as IUser;
        if (action.payload.type == 'facebook') {
            let userData = action.payload.data as any;
            let names = userData.name.split(' ') as string[];
            newUser.first_name = names[0];
            newUser.last_name = names[1];
            newUser.username = names[0].toLowerCase() + '_' + names[1].toLowerCase() + '_';
            newUser.facebook_id = userData.id;
            newUser.email = userData.email;
            newUser.avatar_url = userData.picture.data.url;
            newUser.role = 'PERSONAL';
        } else if (action.payload.type == 'google') {
            let userData = (action.payload.data as GoogleLoginResponse).getBasicProfile();
            newUser.first_name = userData.getGivenName();
            newUser.last_name = userData.getFamilyName();
            newUser.username = newUser.first_name.toLowerCase() + '_' + newUser.last_name.toLowerCase();
            newUser.google_id = userData.getId();
            newUser.email = userData.getEmail();
            newUser.avatar_url = userData.getImageUrl();
            newUser.role = 'PERSONAL';
        } else yield put(fetchError('Invalid social type'));
        // To call async functions, use redux-saga's `call()`.
        const res = yield call(callApi, 'post', API_ENDPOINT, `/user/register`, '', newUser);

        if (!res) {
            yield put(fetchError(res));
            console.log(res);
        } else {
            yield put(tokenReceived(res.jwt));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* watchTokenRequest() {
    yield takeEvery(LoginActionTypes.TOKEN_REQUEST, handleTokenRequest);
}

function* watchSocialTokenRequest() {
    yield takeEvery(LoginActionTypes.TOKEN_SOCIALREQUEST, handleSocialTokenRequest);
}

function* watchRegisterNew() {
    yield takeEvery(LoginActionTypes.REGISTER_NEW, handleRegisterNew);
}

function* watchRegisterSocial() {
    yield takeEvery(LoginActionTypes.REGISTER_SOCIAL, handleRegisterSocial);
}

function* watchLogout() {
    yield takeEvery(LoginActionTypes.LOGOUT, handleLogout);
}

export function* loginSaga() {
    yield all([
        fork(watchTokenRequest),
        fork(watchSocialTokenRequest),
        fork(watchRegisterNew),
        fork(watchLogout),
        fork(watchRegisterSocial),
    ]);
}
