import { IStoreType, IStore } from '../../types/Store';

export enum StoreTypeActionTypes {
    FETCH_STORETYPE = '@@storeType/FETCH_STORETYPE',
    UPDATE_STORETYPE = '@@storeType/UPDATE_STORETYPE',
    STORETYPE_UPDATED = '@@storeType/STORETYPE_UPDATED',
    FETCH_STORETYPES = '@@storeType/FETCH_STORETYPES',
    FETCHED_STORETYPES = '@@admin/FETCHED_STORETYPES',
    REMOVE_STORETYPE = '@@admin/REMOVE_STORETYPE',
    ADD_STORETYPE = '@@admin/ADD_STORETYPE',
    FETCH_SUCCESS = '@@storeType/FETCH_SUCCESS',
    FETCH_ERROR = '@@storeType/FETCH_ERROR',
    FETCH_ALLSUCCESS = 'FETCH_ALLSUCCESS',

    FETCH_STORES = '@@store/FETCH_STORES',
    FETCHED_STORES = '@@store/FETCHED_STORES',
    EDIT_STORE = '@@store/EDIT_STORE',
}

export interface StoreTypeState {
    readonly loading: boolean;
    readonly storeTypes: IStoreType[];
    readonly stores?: IStore[];
    readonly storeType: IStoreType;
    readonly errors?: string;
}
