import { action } from 'typesafe-actions'
import { PostActionTypes } from './types'
import { IPost } from '../../types/Post';
import { Transaction } from '../../types/Transaction';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions


export const fetchSuccess = (
    post: IPost,
) => action(PostActionTypes.FETCH_SUCCESS, {
    post
})
export const fetchPost = () => action(PostActionTypes.FETCH_POST)
export const userUpdated = (post: IPost) => action(PostActionTypes.POST_UPDATED, post)

export const onFetchedPosts = (posts: IPost[]) => action(PostActionTypes.FETCHED_POSTS, posts)
export const fetchPosts = () => action(PostActionTypes.FETCH_POSTS)
export const updatePost = (post: IPost) => action(PostActionTypes.UPDATE_POST, post)
// export const uploadAvatar = (image: File, post: IPost) => action(PostActionTypes.UPLOAD_AVATAR, image)
export const addPost = (post: IPost) => action(PostActionTypes.ADD_POST, post)
export const removePost = (postId: string) => action(PostActionTypes.REMOVE_POST, postId)

export const fetchTransactions = () => action(PostActionTypes.FETCH_TRANSACTIONS);
export const onFetchedTransactions = (transactions: Transaction[]) => action(PostActionTypes.FETCHED_TRANSACTIONS, transactions);

export const fetchAllSuccess = () => action(PostActionTypes.FETCH_ALLSUCCESS)
export const fetchError = (message: string) => action(PostActionTypes.FETCH_ERROR, message)