import { toast } from "react-toastify";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export default async function callApi(
    method: string,
    url: string,
    path: string,
    token: string,
    data?: any
) {
    const res = await fetch(url + path, {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth': token,
        },
        body: JSON.stringify(data)
    })
    if (res.status === 401) {
        toast("Your session expired, please login again", { type: toast.TYPE.ERROR });
        localStorage.clear();
        window.location.href = "/";
    }
    if (res.headers.has('token')) {
        localStorage.setItem('token', res.headers.get('token')!);
    }
    return await res.json()
}

