import { ICategory } from "../../types/Category";

export enum CategoryActionTypes {
    FETCH_CATEGORY = '@@category/FETCH_CATEGORY',
    UPDATE_CATEGORY = '@@category/UPDATE_CATEGORY',
    UPDATE_CATEGORY_MANY = '@@category/UPDATE_CATEGORY_MANY',
    CATEGORY_UPDATED = '@@category/CATEGORY_UPDATED',
    UPLOAD_AVATAR = '@@category/UPLOAD_AVATAR',
    FETCH_CATEGORIES = '@@category/FETCH_CATEGORIES',
    FETCHED_CATEGORIES = '@@admin/FETCHED_CATEGORIES',
    REMOVE_CATEGORY = '@@admin/REMOVE_CATEGORY',
    ADD_CATEGORY = '@@admin/ADD_CATEGORY',
    FETCH_SUCCESS = '@@category/FETCH_SUCCESS',
    FETCH_ERROR = '@@category/FETCH_ERROR',
    FETCH_ALLSUCCESS = "FETCH_ALLSUCCESS"
}

export interface CategoryState {
    readonly loading: boolean;
    readonly categories: ICategory[]
    readonly allCategories: ICategory[]
    readonly category: ICategory
    readonly errors?: string
}