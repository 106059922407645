import { Reducer } from 'redux';
import { SurveyState, SurveyActionTypes } from './types';
// import { IUser } from '../../types/User';
import { Survey } from '../../types/Survey';


// Type-safe initialState!
const initialState: SurveyState = {
    surveys: [],
    errors: undefined,
    loading: false,
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SurveyState> = (state = initialState, action) => {
    switch (action.type) {
        case SurveyActionTypes.FETCHALL ||
        SurveyActionTypes.UPDATE ||
        SurveyActionTypes.REMOVE : {
            return { ...state, loading: true }
        }
        case SurveyActionTypes.FETCHEDALL: {
            return {
                ...state, loading: false,
                surveys: Object.assign([], action.payload),
            }
        }
        // case SurveyActionType.CATEGORY_UPDATED: {
        //     return { ...state, loading: false, category: action.payload }
        // }
        case SurveyActionTypes.ERROR: {
            return { ...state, loading: false, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as surveyReducer }