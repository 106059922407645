import { action } from 'typesafe-actions';
import { StoreTypeActionTypes } from './types';
import { IStoreType, IStore } from '../../types/Store';

export const fetchSuccess = (storeType: IStoreType) =>
    action(StoreTypeActionTypes.FETCH_SUCCESS, {
        storeType,
    });
export const fetchStoreType = (id: string) => action(StoreTypeActionTypes.FETCH_STORETYPE, id);
export const storeTypeUpdated = (storeType: IStoreType) => action(StoreTypeActionTypes.STORETYPE_UPDATED, storeType);

export const onFetchedStoreTypes = (storeTypes: IStoreType[]) =>
    action(StoreTypeActionTypes.FETCHED_STORETYPES, storeTypes);
export const fetchStoreTypes = () => action(StoreTypeActionTypes.FETCH_STORETYPES);
export const updateStoreType = (storeType: IStoreType) => action(StoreTypeActionTypes.UPDATE_STORETYPE, storeType);
export const addStoreType = (storeType: IStoreType) => action(StoreTypeActionTypes.ADD_STORETYPE, storeType);
export const removeStoreType = (id: string) => action(StoreTypeActionTypes.REMOVE_STORETYPE, id);

export const onFetchedStores = (stores: IStore[]) => action(StoreTypeActionTypes.FETCHED_STORES, stores);
export const fetchStores = () => action(StoreTypeActionTypes.FETCH_STORES);
export const updateStore = (store: IStore) => action(StoreTypeActionTypes.EDIT_STORE, store);

export const fetchAllSuccess = () => action(StoreTypeActionTypes.FETCH_ALLSUCCESS);
export const fetchError = (message: string) => action(StoreTypeActionTypes.FETCH_ERROR, message);
