import { ICategory } from '../../types/Category';
import { ISetting, IEffect, BackupCheckResponse, Translation, SettingMap, RegisterPromoCode } from '../../types/Setting';
import { LockKey } from '../../types/LockKey';
import { TitleSentence } from '../../types/TitleSentence';

export enum SettingActionTypes {
    FETCH_SETTINGS = '@@settings/FETCH_SETTINGS',
    FETCHED_SETTINGS = '@@settings/FETCHED_SETTINGS',
    UPDATE_SETTING = '@@settings/UPDATE_SETTING',

    UPLOAD_IMAGE = '@@settings/UPLOAD_IMAGE',

    FETCH_EFFECTS = '@@effects/FETCH_EFFECTS',
    FETCHED_EFFECTS = '@@effects/FETCHED_EFFECTS',
    UPDATE_EFFECT = '@@effects/UPDATE_EFFECT',
    ADD_EFFECT = '@@effects/ADD_EFFECT',
    REMOVE_EFFECT = '@@effects/REMOVE_EFFECT',

    FETCH_LOCKKEYS = '@@lockkey/FETCH_LOCKKEYS',
    FETCHED_LOCKKEYS = '@@lockkey/FETCHED_LOCKKEYS',
    UPDATE_LOCKKEY = '@@lockkey/UPDATE_LOCKKEY',
    ADD_LOCKKEY = '@@lockkey/ADD_LOCKKEY',
    REMOVE_LOCKKEY = '@@lockkey/REMOVE_LOCKKEY',

    DB_BACKUP = '@@db/BACKUP',
    DB_RESTORE = '@@db/RESTORE',
    DB_RESTORED = '@@db/RESTORED',

    CATEGORIES_IMPORT_CSV = '@@db/CATEGORIES_IMPORT_CSV',
    CATEGORIES_IMPORTED_CSV = '@@db/CATEGORIES_IMPORTED_CSV',

    DB_BACKUP_CHECK = '@@db/DB_BACKUP_CHECK',
    DB_BACKUP_CHECKED = '@@db/DB_BACKUP_CHECKED',

    FETCH_TRANSLATION = '@@translation/FETCH',
    FETCHED_TRANSLATION = '@@translation/FETCHED',
    UPDATE_TRANSLATION = '@@translation/UPDATE',

    FETCH_PROMOS = '@@promo/FETCH_PROMOS',
    FETCHED_PROMOS = '@@promo/FETCHED_PROMOS',
    UPDATE_PROMO = '@@promo/UPDATE_PROMO',
    ADD_PROMO = '@@promo/ADD_PROMO',
    REMOVE_PROMO = '@@promo/REMOVE_PROMO',

    FETCH_SENTENCES = '@@sentence/FETCH_SENTENCES',
    FETCHED_SENTENCES = '@@sentence/FETCHED_SENTENCES',
    UPDATE_SENTENCE = '@@sentence/UPDATE_SENTENCE',
    ADD_SENTENCE = '@@sentence/ADD_SENTENCE',
    REMOVE_SENTENCE = '@@sentence/REMOVE_SENTENCE',

    FETCH_SUCCESS = '@@settings/FETCH_SUCCESS',
    FETCH_ERROR = '@@settings/FETCH_ERROR',
}

export interface SettingState {
    readonly loading: boolean;
    readonly settings: ISetting[];
    readonly settingsMap?: SettingMap;
    readonly effects: IEffect[];
    readonly promos?: RegisterPromoCode[];
    readonly sentences?: TitleSentence[];
    readonly lockKeys: LockKey[];
    readonly clientTranslationEn?: Translation;
    readonly clientTranslationHe?: Translation;
    readonly errors?: string;
    readonly backupChecks?: BackupCheckResponse;
}
