import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { CategoryActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updateCategory,
    fetchCategories,
    uploadIcon,
    removeCategory,
    addCategory,
    onFetchedCategories,
    updateCategoryMany,
} from './actions';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

// import { setGameTime } from 'src/actions';
// import { IGameTime } from 'src/types/GameTime';
import { eventChannel } from 'redux-saga';
import { toast } from 'react-toastify';
// import { ICategory } from '../../types/category';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { ICategory } from '../../types/Category';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getUser = (state: ApplicationState) => state.user.user;

// function* handleFetchCategory() {

//     try {
//         // To call async functions, use redux-saga's `call()`.
//         // const login = yield call(callApi, 'post', API_ENDPOINT, `/category/tokenlogin`, '', action.payload)
//         const token = yield select(getToken);
//         console.log(token)
//         if (!token) return yield put(fetchError('Token not in state.'))

//         let categoryRes: ICategory = yield call(callApi, 'get', API_ENDPOINT, '/category/me', token)
//         // console.log("fetched category", categoryRes)

//         yield put(fetchSuccess(categoryRes))

//         // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
//     } catch (err) {
//         console.log(err)
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

function* handleFetchCategories() {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/category/tokenlogin`, '', action.payload)
        // const token = yield select(getToken);
        // console.log(token)
        // if (!token) return yield put(fetchError('Token not in state.'))

        let allCategories: ICategory[] = yield call(callApi, 'get', API_ENDPOINT, '/category/', '');

        let categoriesRes = allCategories
            .filter(l1 => !l1.parent_id)
            .sort((a, b) => a.position - b.position)
            .map(mainCat => {
                mainCat.subCategories = allCategories
                    .filter(l2 => l2.parent_id === mainCat.id)
                    .sort((a, b) => a.position - b.position)
                    .map(subCat => {
                        subCat.subCategories = allCategories
                            .filter(l3 => l3.parent_id === subCat.id)
                            .sort((a, b) => a.position - b.position);
                        return subCat;
                    });
                return mainCat;
            });

        // console.log('fetched categories', categoriesRes, allCategories);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(onFetchedCategories(allCategories, categoriesRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateCategory(action: ReturnType<typeof updateCategory>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/category/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating category'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            // toast("Category data updated", { type: toast.TYPE.SUCCESS });
            yield put(fetchCategories());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateCategoryMany(action: ReturnType<typeof updateCategoryMany>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        let cats = action.payload.map(cat => {
            return { id: cat.id, position: cat.position, parent_id: cat.parent_id } as ICategory;
        });
        console.log('CATS UPD', cats);
        const res: ApiResponse & ApiError = yield call(callApi, 'PATCH', API_ENDPOINT, `/category/many/`, token, cats);

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating categories'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            console.log('Categories updated'); // toast("Category data updated", { type: toast.TYPE.SUCCESS });
            // yield put(fetchCategories())

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveCategory(action: ReturnType<typeof removeCategory>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/category/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing category'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('Category removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchCategories());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddCategory(action: ReturnType<typeof addCategory>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/category/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchCategories());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUploadIcon(action: ReturnType<typeof uploadIcon>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);

        // const formData = new FormData()
        //     formData.append(
        //         'newAvatar',
        //         this.state.selectedFile!,
        //         this.state.selectedFile!.name
        //     );
        const data = new FormData();
        data.append('file', action.payload);
        // data.append('filename', this.fileName.value);

        const res: ApiResponse & ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/category/uploadIcon`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating avatar'));
            toast('Error updating avatar', { type: toast.TYPE.ERROR });
        } else {
            toast('Avatar updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchCategories());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchRequest() { yield takeEvery(CategoryActionTypes.FETCH_CATEGORY, handleFetchCategory) }
function* watchupdateCategory() {
    yield takeEvery(CategoryActionTypes.UPDATE_CATEGORY, handleUpdateCategory);
}
function* watchuploadIcon() {
    yield takeEvery(CategoryActionTypes.UPLOAD_AVATAR, handleUploadIcon);
}
function* watchfetchCategories() {
    yield takeEvery(CategoryActionTypes.FETCH_CATEGORIES, handleFetchCategories);
}
function* watchaddCategory() {
    yield takeEvery(CategoryActionTypes.ADD_CATEGORY, handleAddCategory);
}
function* watchremoveCategory() {
    yield takeEvery(CategoryActionTypes.REMOVE_CATEGORY, handleRemoveCategory);
}
function* watchUpdateMany() {
    yield takeEvery(CategoryActionTypes.UPDATE_CATEGORY_MANY, handleUpdateCategoryMany);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* categorySaga() {
    yield all([
        // fork(watchFetchRequest),
        fork(watchupdateCategory),
        fork(watchuploadIcon),
        fork(watchfetchCategories),
        fork(watchaddCategory),
        fork(watchremoveCategory),
        fork(watchUpdateMany),
    ]);
}
