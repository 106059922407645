export interface LoginRequest {
    username: string;
    password: string;
}

export enum LoginActionTypes {
    TOKEN_REQUEST = '@@login/TOKEN_REQUEST',
    TOKEN_SOCIALREQUEST = '@@login/TOKEN_SOCIALREQUEST',
    TOKEN_RECEIVED = '@@login/TOKEN_RECEIVED',
    TOKEN_LOGIN = '@@login/TOKEN_LOGIN',
    AUTH_COMPLETED = '@@login/AUTH_COMPLETED',
    FETCH_ERROR = '@@login/FETCH_ERROR',
    REGISTER_NEW = '@@login/REGISTER_NEW',
    REGISTER_SOCIAL = '@@login/REGISTER_SOCIAL',
    REGISTER_COMPLETED = '@@login/REGISTER_COMPLETED',
    LOGOUT = '@@login/LOGOUT',
    LOGOUT_COMPLETED = '@@login/LOGOUT_COMPLETED',

}

export interface LoginState {
    readonly loading: boolean;
    readonly token: string;
    readonly loggedIn: boolean;
    readonly errors?: string;
    readonly registerCompleted: boolean;
}