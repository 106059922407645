import { action } from 'typesafe-actions'
import { LoginActionTypes, LoginRequest } from './types'
import { IUser } from '../../types/User';
import { IAuth } from '../../types/Auth';

export const tokenRequest = (auth: IAuth) => action(LoginActionTypes.TOKEN_REQUEST, auth)
export const tokenSocialRequest = (type: string, social_token: string) => action(LoginActionTypes.TOKEN_SOCIALREQUEST, {type, social_token})
export const logout = () => action(LoginActionTypes.LOGOUT)
export const logoutCompleted = () => action(LoginActionTypes.LOGOUT_COMPLETED)

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const tokenReceived = (token: string) => action(LoginActionTypes.TOKEN_RECEIVED, token)
export const authCompleted = (authSignature: string) => action(LoginActionTypes.AUTH_COMPLETED, authSignature)

export const registerNew = (newUser: IUser) => action(LoginActionTypes.REGISTER_NEW, newUser)
export const registerSocial = (type: string, data: any) => action(LoginActionTypes.REGISTER_SOCIAL, {type, data})
export const registerCompleted = () => action(LoginActionTypes.REGISTER_COMPLETED)

export const fetchError = (message: string) => action(LoginActionTypes.FETCH_ERROR, message)

