import { action } from 'typesafe-actions'
import { PageActionTypes } from './types'
import { IPageTemplate } from '../../types/PageTemplate';

export const fetchSuccess = (
    pageTemplate: IPageTemplate,
) => action(PageActionTypes.FETCH_SUCCESS, {
    pageTemplate
})
export const fetchPageTemplate = (url: string) => action(PageActionTypes.FETCH_PAGETEMPLATE, url)
export const pageTemplateUpdated = (pageTemplate: IPageTemplate) => action(PageActionTypes.PAGETEMPLATE_UPDATED, pageTemplate)

export const onFetchedPageTemplates = (pageTemplates: IPageTemplate[]) => action(PageActionTypes.FETCHED_PAGETEMPLATES, pageTemplates)
export const fetchPageTemplates = () => action(PageActionTypes.FETCH_PAGETEMPLATES)
export const updatePageTemplate = (pageTemplate: IPageTemplate) => action(PageActionTypes.UPDATE_PAGETEMPLATE, pageTemplate)
export const addPageTemplate = (pageTemplate: IPageTemplate) => action(PageActionTypes.ADD_PAGETEMPLATE, pageTemplate)
export const removePageTemplate = (pageId: string) => action(PageActionTypes.REMOVE_PAGETEMPLATE, pageId)


export const fetchAllSuccess = () => action(PageActionTypes.FETCH_ALLSUCCESS)
export const fetchError = (message: string) => action(PageActionTypes.FETCH_ERROR, message)