import { action } from 'typesafe-actions';
import { CouponActionTypes } from './types';
import { Coupon } from '../../types/Coupon';

export const fetchCoupons = () => action(CouponActionTypes.FETCHALL);
export const onFetchedCoupons = (coupons: Coupon[]) => action(CouponActionTypes.FETCHEDALL, coupons);

export const updateCoupon = (coupon: Coupon) => action(CouponActionTypes.UPDATE, coupon);

export const addCoupon = (coupon: Coupon) => action(CouponActionTypes.ADD, coupon);
export const removeCoupon = (couponId: string) => action(CouponActionTypes.REMOVE, couponId);

export const fetchError = (message: string) => action(CouponActionTypes.ERROR, message);
