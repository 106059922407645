import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { FilterActionTypes } from './types';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

import { toast } from 'react-toastify';

// import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { IFilter } from '../../types/Filter';
import { ICategory } from '../../types/Category';
import { fetchCategories } from '../category/actions';
import { fetchError, onFetchedFilters, updateFilter, fetchFilters, removeFilter, addFilter } from './actions';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getCategories = (state: ApplicationState) => state.category.allCategories;

function* handleFetchAll() {
    try {
        const categories: ICategory[] = yield select(getCategories);
        if (!categories || categories.length === 0) yield put(fetchCategories());

        let allFilters: IFilter[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/filter/', '');

        if (allFilters.error) {
            console.log(allFilters);
            yield put(fetchError('Error fetching filters'));
            toast('Error fetching filters', { type: toast.TYPE.ERROR });
        } else {
            let allFiltersRes = allFilters.map(ft => {
                let cat = categories.findIndex(ct => ct.id === ft.categoryId);
                if (cat > -1) ft.category = categories[cat];
                return ft;
            });
            // console.log("fetched filters", allFiltersRes)
            yield put(onFetchedFilters(allFiltersRes));
        }
    } catch (err) {
        console.error(err);
        if (err instanceof Error) {
            // // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdate(action: ReturnType<typeof updateFilter>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/filter/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating filter'));
            toast('Error updating filter', { type: toast.TYPE.ERROR });
        } else {
            yield put(fetchFilters());
        }
    } catch (err) {
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemove(action: ReturnType<typeof removeFilter>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/filter/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing filter'));
            toast('Error removing filter', { type: toast.TYPE.ERROR });
        } else {
            toast('Filter removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchFilters());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAdd(action: ReturnType<typeof addFilter>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/filter/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchFilters());
        }
    } catch (err) {
        if (err instanceof Error) {
            // yield put(fetchError(err.stack!))
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* watchUpdate() {
    yield takeEvery(FilterActionTypes.UPDATE, handleUpdate);
}
function* watchFetchAll() {
    yield takeEvery(FilterActionTypes.FETCHALL, handleFetchAll);
}
function* watchAdd() {
    yield takeEvery(FilterActionTypes.ADD, handleAdd);
}
function* watchRemove() {
    yield takeEvery(FilterActionTypes.REMOVE, handleRemove);
}

export function* filterSaga() {
    yield all([fork(watchUpdate), fork(watchFetchAll), fork(watchAdd), fork(watchRemove)]);
}
