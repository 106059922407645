import { action } from 'typesafe-actions'
import { SurveyActionTypes } from './types'
import { Survey } from '../../types/Survey';

export const fetchSurveys = () => action(SurveyActionTypes.FETCHALL)
export const onFetchedSurveys = (surveys: Survey[]) => action(SurveyActionTypes.FETCHEDALL, surveys)

export const updateSurvey = (survey: Survey) => action(SurveyActionTypes.UPDATE, survey)

export const addSurvey = (survey: Survey) => action(SurveyActionTypes.ADD, survey)
export const removeSurvey = (surveyId: string) => action(SurveyActionTypes.REMOVE, surveyId)

export const fetchError = (message: string) => action(SurveyActionTypes.ERROR, message)