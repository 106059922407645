import 'ts-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import 'jquery/src/jquery';

// import './assets/js/jquery-1.11.2.min.js';
// import './assets/js/jquery-ui.min.js';
// import './assets/js/bootstrap.min.js';
// import 'jquery/src/jquery';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/responsive.css';
import 'semantic-ui-css/semantic.min.css';
// import App from './App';
import Main from './Main';
// import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';

const initialState = window.initialReduxState;
const store = configureStore(initialState);

ReactDOM.render(<Main store={store} />, document.getElementById('root'));

// serviceWorker.unregister();
