// /* eslint-disable @typescript-eslint/no-object-literal-type-assertion */
import { Reducer } from 'redux';
import { UserState, UserActionTypes } from './types';
import { IUser, OnlineUser } from '../../types/User';
import { Order } from '../../types/Order';
import { LoginRecord, LoginTime } from '../../types/LoginRecord';
import { Expense } from '../../types/Expense';
import { ICurrency } from '../../types/Currency';
import { mapToObj } from '../../constants';

// Type-safe initialState!
const initialState: UserState = {
    user: {} as IUser,
    users: [] as IUser[],
    orders: [] as Order[],
    expenses: [] as Expense[],
    onlineHistory: [] as LoginRecord[],
    onlineTime: [] as LoginTime[],
    rates: {} as ICurrency,
    errors: undefined,
    loading: false,
    onlineUsers: [] as OnlineUser[],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<UserState> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.FETCH_USER:
        case UserActionTypes.FETCH_ORDERS:
        case UserActionTypes.REFUND_ORDER:
        case UserActionTypes.FETCH_HISTORY:
        case UserActionTypes.TOTAL_STATS:
        case UserActionTypes.SEND_MESSAGE:
        case UserActionTypes.FETCH_EXPENSES:
        case UserActionTypes.ADD_EXPENSE:
        case UserActionTypes.REMOVE_EXPENSE:
        case UserActionTypes.FETCH_RATES:
        case UserActionTypes.UPDATE_EXPENSE:
        case UserActionTypes.FETCH_FINANCE_STATS:
        case UserActionTypes.CANCEL_TRANSACTION:
        case UserActionTypes.FETCH_USERS: {
            return { ...state, loading: true };
        }
        case UserActionTypes.RATES_FETCHED: {
            // console.log('new rates', action.payload);
            return { ...state, loading: false, rates: Object.assign([], action.payload) };
        }
        case UserActionTypes.OK_SEND_MESSAGE: {
            return { ...state, loading: false };
        }
        case UserActionTypes.FETCH_SUCCESS: {
            console.log(action.type, action.payload);
            return {
                ...state,
                loading: false,
                user: Object.assign({}, action.payload.user),
            };
        }
        case UserActionTypes.FETCHED_HISTORY: {
            return {
                ...state,
                loading: false,
                onlineHistory: action.payload.onlineHistory,
                onlineTime: action.payload.onlineTime,
            };
        }
        case UserActionTypes.FETCHED_FINANCE_STATS: {
            return { ...state, loading: false, financeStats: Object.assign({}, action.payload) };
        }
        case UserActionTypes.FETCHED_USERS: {
            return { ...state, loading: false, users: action.payload };
        }
        case UserActionTypes.FETCHED_ORDERS: {
            return { ...state, loading: false, orders: action.payload };
        }
        case UserActionTypes.FETCHED_EXPENSES: {
            return { ...state, loading: false, expenses: Object.assign([], action.payload) };
        }
        case UserActionTypes.USER_UPDATED: {
            return { ...state, loading: false, user: action.payload };
        }
        case UserActionTypes.TOTAL_STATS_LOADED: {
            return { ...state, loading: false, totalStats: action.payload };
        }
        case UserActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.SOCKET_LISTENUSERS_RECEIVED: {
            console.log('SOCKET_LISTENUSERS_RECEIVED', action.payload);
            return { ...state, loading: false, onlineUsers: action.payload as OnlineUser[] };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer };
