import { action } from 'typesafe-actions'
import { FilterActionTypes } from './types'
import { IFilter } from '../../types/Filter';

export const fetchFilters = () => action(FilterActionTypes.FETCHALL)
export const onFetchedFilters = (filters: IFilter[]) => action(FilterActionTypes.FETCHEDALL, filters)

export const updateFilter = (filter: IFilter) => action(FilterActionTypes.UPDATE, filter)

export const addFilter = (filter: IFilter) => action(FilterActionTypes.ADD, filter)
export const removeFilter = (filterId: string) => action(FilterActionTypes.REMOVE, filterId)

export const fetchError = (message: string) => action(FilterActionTypes.ERROR, message)