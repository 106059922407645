import { Reducer } from 'redux';
import { CouponState, CouponActionTypes } from './types';
import { Coupon } from '../../types/Coupon';

// Type-safe initialState!
const initialState: CouponState = {
    coupon: ({} as unknown) as Coupon,
    coupons: ([] as unknown[]) as Coupon[],
    errors: undefined,
    loading: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<CouponState> = (state = initialState, action) => {
    switch (action.type) {
        case CouponActionTypes.FETCHALL || CouponActionTypes.UPDATE || CouponActionTypes.REMOVE: {
            return { ...state, loading: true };
        }
        case CouponActionTypes.FETCHEDALL: {
            return {
                ...state,
                loading: false,
                coupons: Object.assign([], action.payload),
            };
        }
        // case SurveyActionType.CATEGORY_UPDATED: {
        //     return { ...state, loading: false, category: action.payload }
        // }
        case CouponActionTypes.ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as couponReducer };
