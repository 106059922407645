/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { SettingActionTypes } from './types';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

import { toast } from 'react-toastify';
// import { ICategory } from '../../types/category';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { ISetting, IEffect, BackupCheckResponse, Translation, RegisterPromoCode } from '../../types/Setting';
import {
    fetchedSettings,
    fetchError,
    fetchedEffects,
    updateSetting,
    fetchSettings,
    updateEffect,
    fetchEffects,
    removeEffect,
    addEffect,
    uploadImage,
    backupDb,
    restoreDb,
    fetchedLockKeys,
    updateLockKey,
    fetchLockKeys,
    removeLockKey,
    addLockKey,
    backupCheck,
    backupChecked,
    fetchTranslation,
    fetchedTranslation,
    updateTranslation,
    categoriesImportCSV,
    fetchedPromos,
    updatePromo,
    fetchPromos,
    removePromo,
    addPromo,
    addSentence,
    fetchedSentences,
    fetchSentences,
    removeSentence,
    updateSentence,
} from './actions';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';
import axios from 'axios';
import moment from 'moment';
import { LockKey } from '../../types/LockKey';
import { TitleSentence } from '../../types/TitleSentence';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getUser = (state: ApplicationState) => state.user.user;

// function* handleFetchCategory() {

//     try {
//         // To call async functions, use redux-saga's `call()`.
//         // const login = yield call(callApi, 'post', API_ENDPOINT, `/category/tokenlogin`, '', action.payload)
//         const token = yield select(getToken);
//         console.log(token)
//         if (!token) return yield put(fetchError('Token not in state.'))

//         let categoryRes: ICategory = yield call(callApi, 'get', API_ENDPOINT, '/category/me', token)
//         // console.log("fetched category", categoryRes)

//         yield put(fetchSuccess(categoryRes))

//         // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
//     } catch (err) {
//         console.log(err)
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

function* handleFetchTranslation(action: ReturnType<typeof fetchTranslation>) {
    try {
        const lang = action.payload;
        let res: Translation = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/uploads_locales/${lang}/translation.json`,
            '',
        );

        console.log('fetched translation', lang, res);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(fetchedTranslation(lang, res));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateTranslation(action: ReturnType<typeof updateTranslation>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const lang = action.payload.lang;
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/setting/translation/${lang}`,
            token,
            action.payload.translation,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating translation'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(lang + ' Translation updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchTranslation(lang));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSettings() {
    try {
        let res: any = yield call(callApi, 'get', API_ENDPOINT, '/setting/admin', '');

        let settingsRes = res as ISetting[];
        console.log('fetched settingsRes', settingsRes);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(fetchedSettings(settingsRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateSetting(action: ReturnType<typeof updateSetting>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/setting/${action.payload.key}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating setting'));
            toast(res.error, { type: toast.TYPE.ERROR });
        } else {
            toast('Setting data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchSettings());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchEffects() {
    try {
        let effectsRes: IEffect[] = yield call(callApi, 'get', API_ENDPOINT, '/effect/', '');
        console.log('fetched effectsRes', effectsRes);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(fetchedEffects(effectsRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateEffect(action: ReturnType<typeof updateEffect>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/effect/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating effect'));
            toast('Error updating effect', { type: toast.TYPE.ERROR });
        } else {
            toast('Effect data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchEffects());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveEffect(action: ReturnType<typeof removeEffect>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/effect/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing Effect'));
            toast('Error removing category', { type: toast.TYPE.ERROR });
        } else {
            toast('Effect removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchEffects());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddEffect(action: ReturnType<typeof addEffect>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/effect/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchEffects());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchPromos() {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        let res: RegisterPromoCode[] = yield call(callApi, 'get', API_ENDPOINT, '/setting/promoreg', token);
        console.log('fetched promos', res);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        // for (const promo of res) {
        //     promo.usages = promo.usages.length;
        // }

        yield put(fetchedPromos(res));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdatePromo(action: ReturnType<typeof updatePromo>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/setting/promoreg/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating promo code'));
            toast('Error updating promo code', { type: toast.TYPE.ERROR });
        } else {
            toast('Promo code updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchPromos());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemovePromo(action: ReturnType<typeof removePromo>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/setting/promoreg/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing promo code'));
            toast('Error removing promo code', { type: toast.TYPE.ERROR });
        } else {
            toast('Promo code removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchPromos());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddPromo(action: ReturnType<typeof addPromo>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/setting/promoreg/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchPromos());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSentences() {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        let res: TitleSentence[] = yield call(callApi, 'get', API_ENDPOINT, '/setting/sentence', token);
        console.log('fetched sentences', res);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        

        yield put(fetchedSentences(res));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateSentence(action: ReturnType<typeof updateSentence>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/setting/sentence/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating sentence'));
            toast('Error updating sentence', { type: toast.TYPE.ERROR });
        } else {
            toast('Sentence updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchSentences());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveSentence(action: ReturnType<typeof removeSentence>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/setting/sentence/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing sentence'));
            toast('Error removing sentence', { type: toast.TYPE.ERROR });
        } else {
            toast('Sentence removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchSentences());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddSentence(action: ReturnType<typeof addSentence>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/setting/sentence/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchSentences());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchLockKeys() {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        let res: LockKey[] = yield call(callApi, 'get', API_ENDPOINT, '/setting/lockCode', token);
        console.log('fetched lockkeys', res);

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        for (const lockKey of res) {
            lockKey.usagesCount = lockKey.usages.length;
        }

        yield put(fetchedLockKeys(res));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateLockKey(action: ReturnType<typeof updateLockKey>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const res: ApiResponse & ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/setting/lockCode/${action.payload.id}`,
            token,
            action.payload,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error updating lock code'));
            toast('Error updating lock code', { type: toast.TYPE.ERROR });
        } else {
            toast('Lock code updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchLockKeys());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveLockKey(action: ReturnType<typeof removeLockKey>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/setting/lockCode/${action.payload}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing Lock code'));
            toast('Error removing lock code', { type: toast.TYPE.ERROR });
        } else {
            toast('Lock code removed', { type: toast.TYPE.SUCCESS });
            yield put(fetchLockKeys());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAddLockKey(action: ReturnType<typeof addLockKey>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/setting/lockCode/`,
            token,
            action.payload,
        );

        if (res.error) {
            yield put(fetchError(action.type + ' ' + res.error));
            toast(action.type + ' ' + res.error, { type: toast.TYPE.ERROR });
        } else {
            toast(action.type + ' success', { type: toast.TYPE.SUCCESS });
            yield put(fetchLockKeys());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUploadImage(action: ReturnType<typeof uploadImage>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(token);
        const data = new FormData();
        data.append('file', action.payload.image);
        data.append('folder', action.payload.folder);
        data.append('fileName', action.payload.fileName);
        // data.append('filename', this.fileName.value);

        const res: ApiResponse & ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/setting/uploadImage`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error uploading image'));
            toast('Error uploading image', { type: toast.TYPE.ERROR });
        } else {
            toast('Image uploaded', { type: toast.TYPE.SUCCESS });
            yield put(fetchSettings());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRestoreDb(action: ReturnType<typeof restoreDb>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // // console.log(token);
        const dateFrom = moment(action.payload.from).format('YYYY-MM-DD');
        const dateTo = moment(action.payload.to).format('YYYY-MM-DD');
        const data = new FormData();
        data.append('file', action.payload.file);
        data.append('types', JSON.stringify(action.payload.types));
        // data.append('filename', this.fileName.value);

        const res: ApiResponse & ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/setting/restore/${dateFrom}/${dateTo}`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error restoring db'));
            toast('Error restoring db', { type: toast.TYPE.ERROR });
        } else {
            toast('DB restored', { type: toast.TYPE.SUCCESS });
            yield put(fetchSettings());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleCategoriesImportCSV(action: ReturnType<typeof categoriesImportCSV>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // // console.log(token);
        const data = new FormData();
        data.append('file', action.payload);
        // data.append('filename', this.fileName.value);

        const res: ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/setting/import/categoriescsv/`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error importing csv'));
            toast('Error checking backup', { type: toast.TYPE.ERROR });
        } else {
            toast('Categories imported', { type: toast.TYPE.SUCCESS });
            // console.log(res);
            // yield put(backupChecked(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleBackupCheck(action: ReturnType<typeof backupCheck>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // // console.log(token);
        const data = new FormData();
        data.append('file', action.payload);
        // data.append('filename', this.fileName.value);

        const res: BackupCheckResponse & ApiError = yield call(
            callApiUpload,
            'POST',
            API_ENDPOINT,
            `/setting/backup/`,
            token,
            data,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error checking backup'));
            toast('Error checking backup', { type: toast.TYPE.ERROR });
        } else {
            // toast('DB restored', { type: toast.TYPE.SUCCESS });
            // console.log(res);
            yield put(backupChecked(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleBackupDb(action: ReturnType<typeof backupDb>) {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const dateFrom = moment(action.payload.from).format('YYYY-MM-DD');
        const dateTo = moment(action.payload.to).format('YYYY-MM-DD');
        axios(`${API_ENDPOINT}/setting/backup/${dateFrom}/${dateTo}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/gzip',
                auth: token,
            },
            method: 'POST',
            data: {
                types: action.payload.types,
            },
            // responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                console.log('response', response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const fName = `backup_${moment().format('YYYYMMDD_HHmmss')}.tar.gzip`;
                link.setAttribute('download', fName); //or any other extension
                document.body.appendChild(link);
                link.click();
                toast('Downloading backup in file ' + fName, { type: toast.TYPE.SUCCESS });
            })
            .catch(error => {
                console.log(error);
            });
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchRequest() { yield takeEvery(SettingActionTypes.FETCH_CATEGORY, handleFetchCategory) }
function* watchFetchSettings() {
    yield takeEvery(SettingActionTypes.FETCH_SETTINGS, handleFetchSettings);
}
function* watchFetchEffects() {
    yield takeEvery(SettingActionTypes.FETCH_EFFECTS, handleFetchEffects);
}

function* watchUploadImage() {
    yield takeEvery(SettingActionTypes.UPLOAD_IMAGE, handleUploadImage);
}
function* watchUpdateSetting() {
    yield takeEvery(SettingActionTypes.UPDATE_SETTING, handleUpdateSetting);
}
function* watchUpdateEffect() {
    yield takeEvery(SettingActionTypes.UPDATE_EFFECT, handleUpdateEffect);
}
function* watchAddEffect() {
    yield takeEvery(SettingActionTypes.ADD_EFFECT, handleAddEffect);
}
function* watchRemoveEffect() {
    yield takeEvery(SettingActionTypes.REMOVE_EFFECT, handleRemoveEffect);
}
function* watchFetchLockKeys() {
    yield takeEvery(SettingActionTypes.FETCH_LOCKKEYS, handleFetchLockKeys);
}
function* watchUpdateLockKey() {
    yield takeEvery(SettingActionTypes.UPDATE_LOCKKEY, handleUpdateLockKey);
}
function* watchAddLockKey() {
    yield takeEvery(SettingActionTypes.ADD_LOCKKEY, handleAddLockKey);
}
function* watchRemoveLockKey() {
    yield takeEvery(SettingActionTypes.REMOVE_LOCKKEY, handleRemoveLockKey);
}
function* watchBackupDb() {
    yield takeEvery(SettingActionTypes.DB_BACKUP, handleBackupDb);
}
function* watchRestoreDb() {
    yield takeEvery(SettingActionTypes.DB_RESTORE, handleRestoreDb);
}
function* watchBackupCheck() {
    yield takeEvery(SettingActionTypes.DB_BACKUP_CHECK, handleBackupCheck);
}

function* watchCategoriesImportCSV() {
    yield takeEvery(SettingActionTypes.CATEGORIES_IMPORT_CSV, handleCategoriesImportCSV);
}

function* watchFetchTranslation() {
    yield takeEvery(SettingActionTypes.FETCH_TRANSLATION, handleFetchTranslation);
}
function* watchUpdateTranslation() {
    yield takeEvery(SettingActionTypes.UPDATE_TRANSLATION, handleUpdateTranslation);
}

function* watchFetchPromos() {
    yield takeEvery(SettingActionTypes.FETCH_PROMOS, handleFetchPromos);
}
function* watchUpdatePromo() {
    yield takeEvery(SettingActionTypes.UPDATE_PROMO, handleUpdatePromo);
}
function* watchAddPromo() {
    yield takeEvery(SettingActionTypes.ADD_PROMO, handleAddPromo);
}
function* watchRemovePromo() {
    yield takeEvery(SettingActionTypes.REMOVE_PROMO, handleRemovePromo);
}

function* watchFetchSentences() {
    yield takeEvery(SettingActionTypes.FETCH_SENTENCES, handleFetchSentences);
}
function* watchUpdateSentence() {
    yield takeEvery(SettingActionTypes.UPDATE_SENTENCE, handleUpdateSentence);
}
function* watchAddSentence() {
    yield takeEvery(SettingActionTypes.ADD_SENTENCE, handleAddSentence);
}
function* watchRemoveSentence() {
    yield takeEvery(SettingActionTypes.REMOVE_SENTENCE, handleRemoveSentence);
}
// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* settingSaga() {
    yield all([
        fork(watchFetchEffects),
        fork(watchFetchSettings),
        fork(watchUploadImage),
        fork(watchUpdateSetting),
        fork(watchUpdateEffect),
        fork(watchAddEffect),
        fork(watchRemoveEffect),
        fork(watchBackupDb),
        fork(watchRestoreDb),

        fork(watchFetchLockKeys),
        fork(watchUpdateLockKey),
        fork(watchAddLockKey),
        fork(watchRemoveLockKey),
        fork(watchBackupCheck),
        fork(watchFetchTranslation),
        fork(watchUpdateTranslation),
        fork(watchCategoriesImportCSV),

        fork(watchFetchPromos),
        fork(watchUpdatePromo),
        fork(watchAddPromo),
        fork(watchRemovePromo),

        fork(watchFetchSentences),
        fork(watchUpdateSentence),
        fork(watchAddSentence),
        fork(watchRemoveSentence),
    ]);
}
