import { socket } from '../../utils/socket';
import { eventChannel } from 'redux-saga';
import { SocketEvents } from '../../constants'

export function createOnlineUsersChannel() {
    if (socket.hasListeners('listenonline')) return;
    socket.emit('listenonline')
    const subscribe = (emitter: any) => {
        socket.on(SocketEvents.onlineUsers, emitter);

        return () => socket.removeListener(SocketEvents.onlineUsers, emitter);
    };

    return eventChannel(subscribe);
}

// export function createListenPostChannel(postId: string) {
//     const eventName = SocketEvents.listenPost(postId);
//     if (socket.hasListeners(eventName)) return;
//     socket.emit('listenpost', postId)
//     const subscribe = (emitter: any) => {
//         socket.on(eventName, emitter);

//         return () => socket.removeListener(eventName, emitter);
//     };

//     return eventChannel(subscribe);
// }