import { Reducer } from 'redux';
import { FilterState, FilterActionTypes } from './types';
// import { IUser } from '../../types/User';
import { IFilter } from '../../types/Filter';


// Type-safe initialState!
const initialState: FilterState = {
    filters: [],
    errors: undefined,
    loading: false,
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<FilterState> = (state = initialState, action) => {
    switch (action.type) {
        case FilterActionTypes.FETCHALL ||
        FilterActionTypes.UPDATE ||
        FilterActionTypes.REMOVE : {
            return { ...state, loading: true }
        }
        case FilterActionTypes.FETCHEDALL: {
            return {
                ...state, loading: false,
                filters: Object.assign([], action.payload),
            }
        }
        // case FilterActionTypes.CATEGORY_UPDATED: {
        //     return { ...state, loading: false, category: action.payload }
        // }
        case FilterActionTypes.ERROR: {
            return { ...state, loading: false, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as filterReducer }