import { Coupon } from '../../types/Coupon';

export enum CouponActionTypes {
    FETCHALL = '@@coupon/FETCHALL',
    FETCHEDALL = '@@coupon/FETCHEDALL',

    UPDATE = '@@coupon/UPDATE',
    REMOVE = '@@coupon/REMOVE',
    ADD = '@@coupon/ADD',

    SUCCESS = '@@coupon/SUCCESS',
    ERROR = '@@coupon/ERROR',
}

export interface CouponState {
    readonly loading: boolean;
    readonly coupons: Coupon[];
    readonly coupon: Coupon;
    readonly errors?: string;
}
