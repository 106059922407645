export default async function callApiUpload(method: string, url: string, path: string, token: string, data?: any) {
    const res = await fetch(url + path, {
        method,
        headers: {
            'auth': token,
        },
        body: data
    });
    return await res.json();
}
