import { action } from 'typesafe-actions'
import { CategoryActionTypes } from './types'
import { IUser } from '../../types/User';
import { ICategory } from '../../types/Category';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions


export const fetchSuccess = (
    category: ICategory,
) => action(CategoryActionTypes.FETCH_SUCCESS, {
    category
})
export const fetchCategory = () => action(CategoryActionTypes.FETCH_CATEGORY)
export const categoryUpdated = (category: ICategory) => action(CategoryActionTypes.CATEGORY_UPDATED, category)

export const onFetchedCategories = (allCategories: ICategory[], categories: ICategory[]) => action(CategoryActionTypes.FETCHED_CATEGORIES, {allCategories, categories})
export const fetchCategories = () => action(CategoryActionTypes.FETCH_CATEGORIES)
export const updateCategory = (category: ICategory) => action(CategoryActionTypes.UPDATE_CATEGORY, category)
export const updateCategoryMany = (categories: ICategory[]) => action(CategoryActionTypes.UPDATE_CATEGORY_MANY, categories)
export const uploadIcon = (image: File, category: ICategory) => action(CategoryActionTypes.UPLOAD_AVATAR, image)
export const addCategory = (category: ICategory) => action(CategoryActionTypes.ADD_CATEGORY, category)
export const removeCategory = (categoryId: string) => action(CategoryActionTypes.REMOVE_CATEGORY, categoryId)


export const fetchAllSuccess = () => action(CategoryActionTypes.FETCH_ALLSUCCESS)
export const fetchError = (message: string) => action(CategoryActionTypes.FETCH_ERROR, message)