import { Reducer } from 'redux'
import { PostState, PostActionTypes } from './types'
import { IPost } from '../../types/Post';


// Type-safe initialState!
const initialState: PostState = {
    post: {} as IPost,
    posts: [] as IPost[],
    errors: undefined,
    loading: false,
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<PostState> = (state = initialState, action) => {
    switch (action.type) {
        case PostActionTypes.FETCH_POST ||
        PostActionTypes.UPDATE_POST ||
        PostActionTypes.FETCH_TRANSACTIONS ||
        PostActionTypes.FETCH_POSTS : {
            return { ...state, loading: true }
        }
        case PostActionTypes.FETCH_SUCCESS: {
            console.log(action.type, action.payload)
            return {
                ...state, loading: false,
                post: action.payload.post,
            }
        }
        case PostActionTypes.FETCHED_POSTS: {
            return { ...state, loading: false, posts: action.payload }
        }
        case PostActionTypes.FETCHED_TRANSACTIONS: {
            return { ...state, loading: false, transactions: action.payload }
        }
        case PostActionTypes.POST_UPDATED: {
            return { ...state, loading: false, post: action.payload }
        }
        case PostActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload }
        }
        default: {
            return state
        }
    }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as postReducer }